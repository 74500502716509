let timer = null;

export const fnTimeOut = (callback, time = 500) => {
    if (timer) {
        window.clearTimeout(timer);
    }

    timer = window.setTimeout(async () => {
        callback();
    }, time);
};

export const mapIncidentes = (incidentes) => {
    return {
        Lap: incidentes.Lap,
        Fecha: incidentes.Fecha,
        Afectado: incidentes.Afectado,
        Tiempo: incidentes.Tiempo,
        Reportado: incidentes.Reportado,
        Incidente: incidentes.Incidente,
        Resolucion: incidentes.Resolucion,
        Auth: incidentes.Auth,
        Resumen: incidentes.Resumen,
    }
};

// funcion para convertir string '00:00:00' a segundos
export const convertirSegundos = (time) => {
    if (!time || time === '0') return 0;
    const arr = time.split(':');
    const seconds = (+arr[0]) * 60 * 60 + (+arr[1]) * 60 + (+arr[2]);
    return seconds;
}

// funcion para convertir seguntos a string '00:00:000'
export const convertSecondsToTimestamp = (time) => {
    if (!time || time === '0') return '00:00:000';
    const hours = Math.floor(time / 3600);
    time %= 3600;
    const minutes = Math.floor(time / 60);
    time %= 60;
    const milliseconds = Math.floor((time % 1) * 1000);

    const hh = hours.toString().padStart(2, '0');
    const mm = minutes.toString().padStart(2, '0');
    const ss = time.toFixed(0).padStart(2, '0');
    const ms = milliseconds.toString().padStart(3, '0');

    if (hours > 0) {
        return `${hh}:${mm}:${ss}:${ms}`;
    }

    return `${mm}:${ss}:${ms}`;
}

// Ordemaniento quiksort de los resultados tomando en cuenta el finalracetime
export const quickSortResults = (arr) => {
    if (arr.length < 2) return arr;
    const pivot = arr[0];
    const left = [];
    const right = [];

    for (let i = 1; i < arr.length; i++) {
        if (arr[i].finalracetime > 0 && arr[i].finalracetime < pivot.finalracetime) {
            left.push(arr[i]);
        } else {
            right.push(arr[i]);
        }
    }

    return [...quickSortResults(left), pivot, ...quickSortResults(right)];
}