import React from 'react'
import useFetch from 'hooks/useFetch';
import { API_URL2 } from 'constants/api.constant';
import VotosForm from './VotosForm';
import VotosList from './VotosList';
import useAxios from 'hooks/useAxios';

const votosEndpoint = '/getincidentvotes.php';
const votosPostEndpoint = '/votes.php';
export default function Votos({ incidente }) {
    const { data: votosIncidente, fetchData } = useFetch(`${API_URL2}${votosEndpoint}?incidenteid=${incidente.id}`);
    const apiRequest = useAxios();

    const onSubmit = async(values, { setIsCreating }) => {
        const data = {
            incidenteid: incidente.id,
            penaltyid: values.penalty.value,
            penaltydescription: values.penalty.label,
            text: values.comentario || null,
            individualid: incidente.guilty_id
        }

        const config = {
            method: 'post',
            url: `${API_URL2}/${votosPostEndpoint}`,
            data,
        }

        try {
            const response = await apiRequest(config);
            if (response.status === 201) {
                alert('Voto creado correctamente');
                setIsCreating(false);
                fetchData(`${API_URL2}${votosEndpoint}?incidenteid=${incidente.id}`);
                return;
            }

            alert(`Warning: ${response?.data || response.toString()}`);
        } catch (error) {
            alert(`Error al crear el voto \n${error?.response?.data?.error || error.toString()}`);
        }
    }

    return (
        <>
            <VotosForm className='mb-2' onSubmit={onSubmit} />
            <VotosList data={votosIncidente} />
        </>
    )
}
