import { API_URL, API_V5_URl } from "../constants/api.constant";
import ApiService from "./ApiService";


export const apiPostLogin = async (accessToken) => {
    return await ApiService.postData(`${API_V5_URl}/login`, undefined, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json'
		},
		withCredentials: true
	})
}

export const apiPostRegister = async (credentials) => {
	return await ApiService.postData(`${API_V5_URl}/register`, credentials)
}

export const apiLogout = async () => {
	return await ApiService.putData(`${API_V5_URl}/logout`, undefined, {
		withCredentials: true
	})
}

export const postRole = async (data) => {
	return await ApiService.postData(`${API_V5_URl}/users/role`, data, {
		withCredentials: true
	})
}

export const apiGetUsers = async () => {
	return await ApiService.wellFetchData(`${API_V5_URl}/users`,{
		withCredentials: true
	})
}

export const apiDeleteUser = async (data) => {
	return await ApiService.postData(`${API_V5_URl}/users/expulseUser`, data, {
		withCredentials: true
	})
}

