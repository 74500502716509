import { useEffect, useState } from "react"
import { useUserSession } from "userContext";
import { validatePermissionsByModule } from "utils/authUtils"

export function usePermissions({ module, eventId, roundId, projectId, masteradmin}) {
    const { userSession } = useUserSession();
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setLoading(true);
        setPermissions({});
        setErrors(null);

        const fetchData = async () => {
            try {
                let access;
                if (masteradmin) {
                    access = {
                        read: true,
                        write: true
                    }
                }
                else if (roundId) {
                    access = await validatePermissionsByModule({module, userSession, projectId, roundId, eventId, masteradmin});
                } else if (eventId) {
                    access = await validatePermissionsByModule({module, userSession, projectId, eventId, masteradmin});
                } else {
                    throw new Error('No round or event provided');
                }

                if (access !== undefined) {
                    setPermissions(access);
                }
            } catch (error) {
                console.error('Error fetching permissions:', error);
                setErrors(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userSession, module, eventId, roundId, projectId, masteradmin]);

    return { loading, permissions, errors };
}

