import { useNavigate } from "react-router-dom";
import { useUserSession } from "userContext";

export function ProtectedSession({ children }){
  const { userSession } = useUserSession();

  const navigate = useNavigate();

	const isAdmin = userSession?.claims?.superadmin || userSession?.claims?.masteradmin

  console.log('isAdmin', userSession?.claims?.masteradmin);


  if (!userSession) {
    return navigate('/login');
  }

  if(!isAdmin){
    return navigate('/');
  }



  return children;
};
