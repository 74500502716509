import { API_ROUND_DETAILS, API_URL } from "../constants/api.constant";
import ApiService from "./ApiService";

export async function apiGetRounds() {
    return await ApiService.fetchData(`${API_ROUND_DETAILS}`,
    {
        withCredentials: true
    }
    );
}

export async function apiGetRoundsFilter(filter) {
    return await ApiService.fetchData(`${API_ROUND_DETAILS}?${filter}`,
    {
        withCredentials: true
    }
    );
}

export async function apiGetRoundById(id) {
    return await ApiService.fetchData(`${API_URL}/v1/external/getrounds?round_id=${id}`);
}

