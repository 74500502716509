import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useFetch(url) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setData([]);
        // Determine if the API is new based on the URL inside the fetchData function
        const isNewApi = !(url?.includes('gpesportsrd.com/gpt/') || url?.includes('gpesportsrd.com/api') || url?.includes('restapi.gpesportsrd.com'));
        
        await axios.get(url, { withCredentials: isNewApi })
            .then((res) => setData(res.data))
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [url]); // Removed isOldApi from dependency array as it's no longer used outside fetchData

    useEffect(() => {
        if (url) fetchData();
    }, [fetchData, url]);

    return { data, loading, error, fetchData };
}
