import React from 'react'
import Navbar from './Navbar'

export default function Header() {
  return (
    <header className='bg-white border-gray-200 px-4 lg:px-6 py-2.5 shadow-md'>
        <Navbar />
    </header>
  )
}
