import classNames from 'classnames'
import React from 'react'
import { roundsNavigation } from 'config/nav.config'
import { Link, useLocation, useParams } from 'react-router-dom'

export default function RoundHeader() {
  const { id } = useParams();
  const location = useLocation()
  const isActive = (path) => {
    return location.pathname === path
  }

  return (
    <>
      <div className='mb-2 border-b flex overflow-x-auto'>
        {roundsNavigation.map((item, index) => (
          <Link
            key={index}
            to={item.path.replace(':id', id)}
            className={
              classNames(
                'cursor-pointer font-semibold py-2 px-4 border-b-2 focus:outline-none min-w-max',
                { 'border-transparent text-gray-600 hover:text-blue-600': !isActive(item.path.replace(':id', id)) },
                { 'border-blue-600 text-blue-700': isActive(item.path.replace(':id', id)) }
              )
            }
          >
            <span>
              {item.label}
            </span>
          </Link>
        ))}
      </div>
    </>
  )
}
