import Modal from "components/Modal";
import { API_URL2, API_URL3 } from "constants/api.constant";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { HiSave, HiX } from "react-icons/hi";
import Select from 'react-select';

const preraceEndpoint = '/getpreraces.php';
const postPreraceEndpoint = '/udpfix';
export default function ModalEvents({ open, onClose, eventId, refresh }) {
  const apiRequest = useAxios();
  const [races, setRaces] = useState([]);
  const [selectedRace, setSelectedRace] = useState(null);
  const [isLoadingRaces, setIsLoadingRaces] = useState(false);

  const onConfirm = async() => {
    if (!selectedRace?.value) {
      return alert('Seleccione una carrera');
    }

    const data = {
      raceId: selectedRace.entireObject.raceId,
      eventID: parseInt(eventId, 10)
    }
    const resp = await apiRequest({ url: `${API_URL3}${postPreraceEndpoint}`, method: 'POST', data });

    if (resp.data) {
      alert('Carrera editada correctamente');
      onClose();
      refresh();
    }
  }

  useEffect(() => {
    const getRaces = async () => {
      setIsLoadingRaces(true);
      const resp = await apiRequest({ url: `${API_URL2}${preraceEndpoint}` });

      if (resp.data) {
        setRaces(resp.data.data.map(x => ({
          value: x.raceId,
          label: x.raceName,
          entireObject: x
        })))
      }

      setIsLoadingRaces(false);
    }
    getRaces()
  }, [apiRequest]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className='min-h-[400px]'>
        <div className='mb-2'>
          <h2 className='text-2xl font-bold'>Editar Carrera</h2>
        </div>

        <div className='mb-4'>
          <label className='text-gray-700'>Carreras</label>
          <Select
            className='w-full z-50'
            placeholder='Seleccione una carrera'
            value={selectedRace}
            options={races}
            onChange={(value) => setSelectedRace(value)}
            isLoading={isLoadingRaces}
          />
        </div>

        <div className='mb-6'>
          {/* {selectedRace?.value && (
            <pre>{JSON.stringify(selectedRace, null, 2)}</pre>
          )} */}

          {
            selectedRace?.entireObject && Object.keys(selectedRace.entireObject).map((key, index) => (
              <div key={index} className='flex gap-3 py-2 odd:bg-white even:bg-slate-50'>
                <p className='w-56 font-bold'>
                  {key}
                </p>
                <p className='w-full'>
                  {selectedRace.entireObject[key] || 'N/A'}
                </p>
              </div>
            ))
          }
        </div>

        <div className=''>
        <button type='button' onClick={onClose} className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded mr-2'>
          <span className='flex items-center gap-1'>
            <HiX className='text-lg' /> Cancelar
          </span>
        </button>
        <button onClick={onConfirm} type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded'>
          <span className='flex items-center gap-1'>
            <HiSave className='text-lg' /> Confirmar
          </span>
        </button>
      </div>
      </div>
    </Modal>
  )
}