import classNames from 'classnames'
import Loading from 'components/Loading'
import React from 'react'
import { HiPencilAlt } from 'react-icons/hi'

export default function TabsResultados({ data, selectedEvent, onChange, loading, onEdit}) {

  if (loading) return <Loading />
  return (
    <div className='flex overflow-x-auto border-b'>
      {
        data.map((item, index) => (
          <div
            key={index}
            className={
              classNames(
                'cursor-pointer font-semibold py-2 px-4 border-b-2 focus:outline-none min-w-max',
                { 'border-transparent text-gray-600 hover:text-blue-600': selectedEvent?.id !== item.id },
                { 'border-blue-600 text-blue-700': selectedEvent?.id === item.id }
              )
            }
            onClick={() => onChange(item)}
            >
            <div className='flex items-center'>
              <span>{item.name} ({item.id})</span>
              {onEdit &&
                <HiPencilAlt className='cursor-pointer ml-2' title='Editar' onClick={() => onEdit(item)}
              />}
            </div>
          </div>
        ))
      }
    </div>
  )
}
