import React, { useCallback, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch';
import RoundFilter from './RoundFilter';
import useAxios from 'hooks/useAxios';
import RoundList from './RoundList';
import { getFromLocalStorage, saveToLocalStorage } from 'utils/storage';
import { API_COMPETITIONS, API_LAST_ROUNDS, API_ROUND_DETAILS } from 'constants/api.constant';


export default function Rounds() {
    const apiRequest = useAxios();
    const { data: competitions, loading: loadingCompetitions} = useFetch(`${API_COMPETITIONS}`);
    const [rounds, setRounds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCompetition, setSelectedCompetition] = useState(getFromLocalStorage('filter.rounds'));

    const onChangeFilter = async(filter) => {
        saveToLocalStorage('filter', {
            ...getFromLocalStorage('filter'),
            rounds: filter
        })
        setSelectedCompetition(filter);
    }

    const getRoundsByCompetition = useCallback(async(id) => {
        try {
            const resp = await apiRequest({ url:`${API_ROUND_DETAILS}?competition_id=${id}` });
            return await resp.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }, [apiRequest]);

    // const getRoundById = useCallback(async(id) => {
    //     try {
    //         const resp = await apiRequest({ url:`${API_URL}/${roundsEndpoint}?round_id=${id}` });
    //         return await resp.data;
    //     } catch (error) {
    //         console.error(error);
    //         return null;
    //     }
    // }, [apiRequest]);

    const loadInitialRounds = useCallback(async() => {
        // if (!competitions) return;
        // let rounds = [];

        // competitions.map(async(competition) => {
        //     if (competition.nextRound_id !== 0) {
        //         const round = await getRoundById(competition.nextRound_id);
        //         if (round) rounds = [...rounds, ...round];
        //     }

        //     if (competition.lastRound_id !== 0) {
        //         const round = await getRoundById(competition.lastRound_id);
        //         if (round) rounds = [...rounds, ...round];
        //     }

        //     setRounds(rounds);
        // });

        setLoading(true);
        const resp = await apiRequest({ url: `${API_LAST_ROUNDS}` });
        if (resp?.data) {
            setRounds(resp.data);
        }
        setLoading(false);
    }, [apiRequest]);

    useEffect(() => {
        loadInitialRounds();
    }, [loadInitialRounds]);

    useEffect(() => {
        const getRounds = async() => {
            setLoading(true);
            const resp = await getRoundsByCompetition(selectedCompetition)
            if (resp) setRounds(resp);
            setLoading(false);
        }

        selectedCompetition ? getRounds() : loadInitialRounds();
    } , [selectedCompetition, getRoundsByCompetition, loadInitialRounds]);

    useEffect(() => {
        setLoading(loadingCompetitions);
    }, [loadingCompetitions]);

    return (
        <>
            <div className='mb-6'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-2xl font-bold'>Rounds</h2>
                    <RoundFilter className='z-20' value={selectedCompetition} onChange={onChangeFilter} competitions={competitions} loading={loadingCompetitions} />
                </div>
            </div>

            <RoundList data={rounds} loading={loading} />
        </>
    )
}
