
import { useState } from "react";
import Modal from "./Modal";
import { RoleSelect } from "./RoleSelect";
import { Button } from "./ui/Button";
import { createOrUpdateUserPermissionsAndRoles } from "utils/roleUtils";
import { apiDeleteUser } from "services/AuthService";
import { auth, db } from "config/firebase.config";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { refreshUserPermissions } from "utils/authUtils";
import { LoadingSpinner } from "./ui/LoadingSpinner";
import PermissionsByRole from "./ui/PermissionsList";
import { createLog } from "utils/logsUtils";

export function ModalRoles({ open, onClose, user, selectedRole, projectId }) {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [updatedPermissions, setUpdatedPermissions] = useState({
        role: '',
        userUid: user?.uid,
        projectId,
        affectedEmail: user?.email
    });

    const userAuth = auth.currentUser


    const sendNewRole = () => {
        setLoading(true)
        createOrUpdateUserPermissionsAndRoles({newUser: updatedPermissions, projectId})
        .then(() => alert('Rol cambiado'))
        .then(() => createLog({authorEmail: userAuth.email, action: `Cambio el rol a ${updatedPermissions.role}`, projectId: updatedPermissions.projectId, affected: updatedPermissions.affectedEmail, date: new Date()}))
        .then(() => refreshUserPermissions(userAuth))
        .then(() => navigate(0))
        .then(() => setLoading(false))
    }

    const handleExpulseUser = async () => {
        try {
            setLoading(true)
            const userRef = doc(db, 'users', `${user?.uid}`);
            const projectRef = doc(userRef, `permissionsProjects`, `${projectId}`);
            const eventsRef = collection(userRef, 'permissionsEvents');
            const roundsRef = collection(userRef, 'permissionsRounds');
    
            const roundSnapshot = await getDocs(roundsRef);
            const eventSnapshot = await getDocs(eventsRef);
    
            const deleteRoundPromises = roundSnapshot.docs.map(async (round) => {
                await deleteDoc(round.ref);
            });
    
            const deleteEventPromises = eventSnapshot.docs.map(async (event) => {
                await deleteDoc(event.ref);
            });

            const deleteRoundPromise =  Promise.all(deleteRoundPromises);
            const deleteEventPromise =  Promise.all(deleteEventPromises);

            await apiDeleteUser({ userUid: user?.uid, projectId, role: user?.role, remove: true })
            .then(() => alert('Usuario expulsado'))
            .then(() => createLog({authorEmail: userAuth.email, action: `Expulsó a`, projectId: updatedPermissions.projectId, affected: updatedPermissions.affectedEmail, date: new Date()}))
            .catch(error => alert('Error al expulsar usuario:', error));
            
            await Promise.all([deleteRoundPromise, deleteEventPromise, deleteDoc(projectRef)]);
            refreshUserPermissions(userAuth)
            .then(() => navigate(0))
            .then(() => setLoading(false))
    
        } catch (error) {
            console.error('Error al expulsar usuario:', error);
            alert('Ocurrió un error al expulsar usuario');
        }
    };


    return (
            <>
                <LoadingSpinner loading={loading}/> 
                <Modal className="flex justify-center" open={open} onClose={onClose} >
                        <h1>Administrar Usuario: {user?.email}</h1>
                        <RoleSelect userRole={user?.role} selectedRole={selectedRole} handleRoleChange={(e) => { setUpdatedPermissions({ ...updatedPermissions, role: e })}} userId={user?.id}></RoleSelect>
                        <div className="flex justify-center align-middle items-center gap-2">
                            <Button onClick={sendNewRole}>Aplicar cambios</Button>
                            <Button className={'bg-red-600'} onClick={handleExpulseUser}>Eliminar usuario del proyecto</Button>
                        </div>
                        <div className="flex flex-col w-1/2"> 
                            <PermissionsByRole role={updatedPermissions.role ?? user?.role}></PermissionsByRole>
                        </div>
                </Modal>
            </>
    )
}