import React from 'react'
import useFetch from 'hooks/useFetch';
import { API_URL } from 'constants/api.constant';

const eventsEndpoint = 'v1/external/getevents';
export default function EventsList({ roundId }) {
    const { data, loading } = useFetch(`${API_URL}/${eventsEndpoint}?projectround_id=${roundId}`);
    return (
        <div className=''>
            <div className='mb-2'>
                <h3 className='text-xl font-bold mb-2'>Events</h3>
            </div>

            {
                data && data.map((event) => (
                    <div key={event.id} className='mb-2'>
                        <p className='flex items-center font-semibold'>
                            <span>{event.name} ({event.id}) - {event.preresult_raceid}</span>
                        </p>
                    </div>
                ))
            }

            { loading && <p>Loading...</p> }
        </div>
    )
}
