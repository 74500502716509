function PermissionsByRole({role}) {
    switch (role) {
      case "admin":
        return <ul>
          Home: <UserPermissions permissions={"full"} />
          Importar Resultados: <UserPermissions permissions={"full"} />
          Parc Ferme: <UserPermissions permissions={"full"} />
          Incidentes: <UserPermissions permissions={"full"} />
          Resultados: <UserPermissions permissions={"full"} />
          Redes sociales: <UserPermissions permissions={"full"} />
          Season Status: <UserPermissions permissions={"full"} />
        </ul>;
      case "director":
        return <>
          Home: <UserPermissions permissions={"full"} />
          Importar Resultados: <UserPermissions permissions={"full"} />
          Parc Ferme: <UserPermissions permissions={"full"} />
          Incidentes: <UserPermissions permissions={"full"} />
          Resultados: <UserPermissions permissions={"full"} />
          Redes sociales: <UserPermissions permissions={"full"} />
          Season Status: <UserPermissions permissions={"full"} />
        </>;
      case "juez":
        return <>
          Home: <UserPermissions permissions={"read"} />
          Importar Resultados: <UserPermissions permissions={"no"} />
          Parc Ferme: <UserPermissions permissions={"no"} />
          Incidentes: <UserPermissions permissions={"full"} />
          Resultados: <UserPermissions permissions={"no"} />
          Redes sociales: <UserPermissions permissions={"no"} />
          Season Status: <UserPermissions permissions={"no"} />
        </>;
      case "colaborador":
        return <>
          Home: <UserPermissions permissions={"read"} />
          Importar Resultados: <UserPermissions permissions={"full"} />
          Parc Ferme: <UserPermissions permissions={"read"} />
          Incidentes: <UserPermissions permissions={"no"} />
          Resultados: <UserPermissions permissions={"read"} />
          Redes sociales: <UserPermissions permissions={"read"} />
          Season Status: <UserPermissions permissions={"read"} />
        </>;
      case "invitado":
        return <ul>
          Home: <UserPermissions permissions={"read"} />
          Importar Resultados: <UserPermissions permissions={"no"} />
          Parc Ferme: <UserPermissions permissions={"read"} />
          Incidentes: <UserPermissions permissions={"no"} />
          Resultados: <UserPermissions permissions={"read"} />
          Redes sociales: <UserPermissions permissions={"read"} />
          Season Status: <UserPermissions permissions={"read"} />
        </ul>;
      default:
        return <>Este rol tiene permisos desconocidos</>;
    }
  }

  function UserPermissions({ permissions }) {
    switch (permissions) {
      case "full": 
        return <li className="text-green-500 text-center">Completos</li>
      case "read":
        return <li className="text-blue-500 text-center">Lectura</li>
      default:
        return <li className="text-red-500 text-center">No</li>
    }
  }
  
  export default PermissionsByRole