import classNames from 'classnames';
import useAxios from 'hooks/useAxios';
import useFetch from 'hooks/useFetch';
import React, { useState, useEffect, useCallback } from 'react';
import Table, { THead, TBody, Tr, Th, Td, LoadingTableContent, NoDataRow } from 'components/Table'; // Replace 'components/Table' with the actual path to your Table component
import { API_URL, API_URL2 } from 'constants/api.constant'; // Replace 'constants/api.constant' with the actual path to your constants

const TeamName = ({ teamId }) => {
  const apiRequest = useAxios();
  const [teamName, setTeamName] = useState('...');

  useEffect(() => {
    const getTeamName = async () => {
      const response = await apiRequest({ url: `${API_URL2}/teams.php?teamid=${teamId}` });
      setTeamName(response?.data[0]?.short_name || 'N/A');
    };

    if (teamId) {
      getTeamName();
    }
  }, [apiRequest, teamId]);

  return <span>{teamName}</span>;
};

export default function ResultadosFinalesList({ eventId }) {
  const apiRequest = useAxios();
  const { data: results, loading } = useFetch(`${API_URL}/v1/external/getResultsByEventId/${eventId}`);

  return (
    <>
      <div className='flex justify-between mb-4'>
        <h2 className='text-2xl font-bold'>
          <a href={`http://20.121.40.254:5002/?eventid=${eventId}&template_id=1`} target='_blank' rel='noopener noreferrer'>PODIO</a>
          {' | '}
          <a href={`http://ig.gpesportsrd.com/images/igwin/${eventId}/0`} target='_blank' rel='noopener noreferrer'>IGWIN</a>
        </h2>
      </div>
      <Table>
        <THead>
          <Tr>
            <Th>Rank</Th>
            <Th>Piloto</Th>
            <Th>Equipo</Th>
            <Th>IGWIN</Th>
          </Tr>
        </THead>
        <TBody>
          <LoadingTableContent colSpan='4' loading={loading} />
          {results.map((result, index) => (
            <Tr className='hover:bg-gray-100' key={index}>
              <Td>{result.rank}</Td>
              <Td>{`${result.first_name} ${result.last_name}`}</Td>
              <Td>
                <TeamName teamId={result.team_id} />
              </Td>
              <Td>
                <a href={`http://ig.gpesportsrd.com/images?file=igwin-${eventId}-${result.individual_id}.png`} target='_blank' rel='noopener noreferrer'>
                  <img 
                    src={`http://ig.gpesportsrd.com/images?file=igwin-${eventId}-${result.individual_id}.png`} 
                    alt={`IGWIN for ${result.first_name} ${result.last_name}`} 
                    style={{ width: '50px', height: 'auto' }}
                  />
                </a>
                <a href={`http://ig.gpesportsrd.com/images/igwin/${eventId}/${result.individual_id}`} target='_blank' rel='noopener noreferrer' style={{ marginLeft: '10px' }}>
                  <img 
                    src="https://gpesportsrd.com/images/whatsapp.svg" 
                    alt="WhatsApp" 
                    style={{ width: '24px', height: '24px' }}
                  />
                </a>
              </Td>
            </Tr>
          ))}
          <NoDataRow show={!loading && results.length === 0} colSpan='4' />
        </TBody>
      </Table>
    </>
  );
}