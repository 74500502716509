import { db } from "config/firebase.config";
import { doc, setDoc } from "firebase/firestore";
import { postRole } from "services/AuthService";
import { apiGetEvents } from "services/EventService";
import { apiGetRoundsFilter } from "services/RoundService";

export function decidePermissions(role, customPermissions = {}) {
    switch (role) {
        case 'admin':
        case 'director':
            return {
                home: {
                    read: true,
                    write: true
                },
                importarResultados: {
                    read: true,
                    write: true
                },
                redesSociales: {
                    read: true,
                    write: true
                },
                resultados: {
                    read: true,
                    write: true
                },
                incidentes: {
                    read: true,
                    write: true
                },
                parcFerme: {
                    read: true,
                    write: true
                },
                seasonStatus: {
                    read: true,
                    write: true
                },
                ...customPermissions
            };

        case 'juez':
            return {
                home: {
                    read: true,
                    write: false
                },
                importarResultados: {
                    read: false,
                    write: false
                },
                redesSociales: {
                    read: false,
                    write: false
                },
                resultados: {
                    read: false,
                    write: false
                },
                incidentes: {
                    read: true,
                    write: true
                },
                parcFerme: {
                    read: false,
                    write: false
                },
                seasonStatus: {
                    read: false,
                    write: false
                },
                ...customPermissions
            };

            case 'colaborador':
            return{
                home: {
                    read: true,
                    write: false
                },
                importarResultados: {
                    read: true,
                    write: true
                },
                redesSociales: {
                    read: true,
                    write: false
                },
                resultados: {
                    read: true,
                    write: false
                },
                incidentes:{
                    read: false,
                    write: false
                },
                parcFerme:{
                    read: true,
                    write: false
                },
                seasonStatus:{
                    read: true,
                    write: false
                },
                ...customPermissions
            }

            case 'invitado':
                return{
                    home: {
                        read: true,
                        write: false
                    },
                    importarResultados: {
                        read: false,
                        write: false
                    },
                    redesSociales: {
                        read: true,
                        write: false
                    },
                    resultados: {
                        read: true,
                        write: false
                    },
                    incidentes:{
                        read: false,
                        write: false
                    },
                    parcFerme:{
                        read: true,
                        write: false
                    },
                    seasonStatus:{
                        read: true,
                        write: false
                    },
                    ...customPermissions
                }


        default:
            return {
                ...customPermissions
            };
    }
}

export async function createOrUpdateUserPermissionsAndRoles({newUser, projectId}) {
    const [roundsResponse, eventsResponse] = await Promise.all([
        apiGetRoundsFilter(`project_id=${projectId}`),
        apiGetEvents(`project_id=${projectId}`)
    ]);

    const rounds = roundsResponse.data;
    const events = eventsResponse;
    
    postRole(newUser)
    const permissions = decidePermissions(newUser.role);
    const userRef = doc(db, 'users', `${newUser.userUid}`);
    const permissionsRef = doc(userRef, 'permissionsProjects', `${projectId}`);
    setDoc(permissionsRef, {projectId, permissions, role: newUser.role })

    const roundsMap = rounds?.map( async (round) => {
        try {
            const roundsRef = doc(userRef, `/permissionsRounds/${round.round_id}`);
            return await setDoc(roundsRef, { roundId: round.round_id, permissions, role: newUser.role });
        } catch (error) {
            console.error(error)
        }
    })

    const eventsMap = events?.map( async (event) => {
        try {
            const eventsRef = doc(userRef, `permissionsEvents/${event.id}`);
            return await setDoc(eventsRef, {eventId: event.id, permissions, role: newUser.role })
        } catch (error) {
            console.error(error)
        }
    })

    const promiseEvents = Promise.all(eventsMap)
    const promiseRounds = Promise.all(roundsMap)

    return await Promise.all([promiseEvents, promiseRounds])
    
}
