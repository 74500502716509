// utils/whatsapp.js

export async function sendWhatsAppMessage(user_id, individual_id, message) {
    try {
      const payload = {
        user_id,
        individual_id,
        message,
      };
  
      const response = await fetch('https://wappi.gpesportsrd.com/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to send WhatsApp message to user ${user_id}`);
      }
  
      // Handle success
      console.log(`WhatsApp message sent to user ${user_id}`);
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  }
  