const { Navigate } = require("react-router-dom");
const { useUserSession } = require("userContext");


export function ProtectedAuth({ children }) {
    const { user } = useUserSession();

    if (!user) {
        return children;
    } else {
        return <Navigate to="/" />
    }
}


