const { View, Text } = require("@react-pdf/renderer");

export default function Content({ incidente, styles, contentStyles, textStyles }) {
  return (
      <View style={styles}>
          <View style={contentStyles}>
              <Text style={textStyles}>({incidente.Reportado} | {incidente.Afectado})</Text>
              <Text style={textStyles}>Incidente: L{incidente.Lap} - {incidente.Incidente}</Text>
              <Text style={textStyles}>Resolucion: {incidente.Resolucion} ({incidente.Reportado})</Text>
          </View>
      </View>
  )
}