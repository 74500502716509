// util/twitch.js

// Hardcoded values for demonstration purposes
const CLIENT_ID = 'nt8o2zkfwcl2fripymlzu0on11z8qb'; // Replace with your actual Twitch Client ID
const ACCESS_TOKEN = 'lfhwpo5v8arbd640kzr42ip1w3b015'; // Replace with your actual access token

/**
 * Extracts the video ID from a Twitch video URL.
 * @param {string} url - The full URL to a Twitch video.
 * @returns {string} The extracted video ID.
 */
const extractVideoId = (url) => {
  const match = url.match(/(?:videos\/)(\d+)/);
  return match ? match[1] : null;
};

/**
 * Fetches the thumbnail URL for a given Twitch video ID.
 * @param {string} videoId - The ID of the Twitch video.
 * @returns {Promise<string>} The URL of the video's thumbnail.
 */
const fetchTwitchThumbnail = async (videoId) => {
  const endpoint = `https://api.twitch.tv/helix/videos?id=${videoId}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Client-ID': CLIENT_ID,
        'Authorization': `Bearer ${ACCESS_TOKEN}`,
      },
    });
    const data = await response.json();
    if (data.data && data.data.length > 0) {
      // Assuming you want the thumbnail of the first returned video
      return data.data[0].thumbnail_url.replace('%{width}', '640').replace('%{height}', '360');
    } else {
      throw new Error('Twitch Video not found');
    }
  } catch (error) {
    console.error('Failed to fetch Twitch thumbnail:', error);
    return null;
  }
};

const secondsToTwitchTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h${minutes}m${secs}s`;
  };
  
/**
 * Determines if a URL is a Twitch or YouTube video and extracts the video ID.
 * @param {string} url - The URL of the video.
 * @returns {Object} An object containing the type ('twitch' or 'youtube') and the video ID.
 */
const identifyVideoPlatformAndId = (url) => {
    console.log("Received URL:", url); 
    try {
      const urlObj = new URL(url);
      let videoId, platform;
  
      // For Twitch videos
      if (urlObj.hostname.includes('twitch.tv')) {
        videoId = extractVideoId(url);
        platform = 'twitch';
      }
      // For YouTube videos
      else if (urlObj.hostname === 'youtu.be') {
        videoId = urlObj.pathname.substring(1);
        platform = 'youtube';
      }
      else if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
        videoId = urlObj.searchParams.get('v');
        platform = 'youtube';
      }
      // If neither
      else {
        throw new Error('URL does not match Twitch or YouTube');
      }
  
      return { platform, videoId };
    } catch (error) {
      console.error('Error identifying video platform:', error);
      return null;
    }
  };
  
  export { fetchTwitchThumbnail, extractVideoId, secondsToTwitchTime, identifyVideoPlatformAndId };
