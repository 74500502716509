import React from 'react'
import Select from 'react-select';
import classNames from 'classnames';
export default function RoundFilter({ value, onChange, className, competitions, loading }) {
    const [options, setOptions] = React.useState([]);

    const onChangeCategory = (selectedOption) => {
        if (!selectedOption) {
            return onChange(null);
        }
        onChange(selectedOption.value);
    }

    React.useEffect(() => {
        if (!competitions) return;
        setOptions(competitions.map(competition => ({value: competition.id, label: competition.name})));
        
    }, [competitions]);

    return (
        <>
            <div className={classNames(className)}>
                <div className='flex flex-col md:flex-row md:items-center md:justify-between'>
                    <div className='flex flex-col md:flex-row md:items-center'>
                        <div className='flex flex-col md:flex-row md:items-center'>
                            <label className='mr-2'>Categorias</label>
                            <Select
                                name='category'
                                className='w-full md:w-64'
                                options={options}
                                value={options.find(competition => competition.value === value)}
                                placeholder='Buscar por categoria'
                                isLoading={loading}
                                isClearable
                                isSearchable
                                onChange={onChangeCategory}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
