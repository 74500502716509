import { API_URL } from 'constants/api.constant';

async function getBonusPoints(results) {
  let projectRoundId = results[0].roundId;
  let eventID = results[0].eventID;
  // console.log("Getting bonus points of project ID:", results[0].roundId)
  // console.log("The ID is: ", results[0].eventID);
  // console.log("ResultsChecked: ", results);
  const res = await fetch(`${API_URL}/v1/external/getevents?projectround_id=${projectRoundId}`);

  if (!res.ok) {
    throw new Error(`Error trying to get bonus points from API. STATUS: ${res.status}`);
  }

  const data = await res.json();

  // Filtrar el array obtenido del api dependiendo del results.id y luego extraer el campo fastlapbonustype
  let filteredIdArray = data.filter((item) => String(item.id) === String(eventID));
  let fastLapBonusType = filteredIdArray[0]?.fastlapbonustype;

  // Cargar el bestLapTime
  let bestLapTime = parseFloat(results[0]?.bestLapTime);
  let bestLapTimeId = results[0]?.id;

  // Cuando fastLabBonusType es 0, asignar 0 a todos los bonusPoints
  if (fastLapBonusType === 0) {
    console.log("fastLapBonusType es 0. Asignando 0 a todos los bonusPoints");
    results = results.map(x => {
      return {
        ...x,
        bonusPoints: 0
      }
    }
    )
    return results;
  }

  // Cuando fastLabBonusType es 1, asignar 1 punto al mejor tiempo, sólo si su posición es menor a 11
  if (fastLapBonusType === 1) {
    console.log("FastLapBonusType es 1. Asignando 1 punto al mejor tiempo, sólo si su posición es menor a 11");
    // Determinar el mejor tiempo
    for (let i = 0; i < results.length; i++) {
      if (parseFloat(results[i].bestLapTime) > 0 && parseFloat(results[i].bestLapTime) <= bestLapTime) {
        bestLapTime = parseFloat(results[i].bestLapTime);
        bestLapTimeId = results[i].id;
      }
    }

    // Buscar la posición del mejor tiempo en el array
    const newResults = results.map(x => {
      if (x.id === bestLapTimeId) {
        let position = results.findIndex((item) => item.id === bestLapTimeId);
        console.log("Posición del mejor tiempo: ", position);

        // Si la posición es menor a 11, asignar 1 punto
        if (position < 11) {
          console.log("Mejor tiempo menor a posición 11. Asignando 1 punto");
          return { ...x, bonusPoints: 1 }
        }
      }
      return {
        ...x,
        bonusPoints: 0
      }
    })

    return newResults;
  }
}

export default getBonusPoints;