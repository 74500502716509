import classNames from 'classnames';
import React from 'react'

function Container(props) {
    const { className, children, asElement: Component = 'div', } = props;

    return (
        <Component className={`${classNames(className, 'container mx-auto')}`}>
            {children}
        </Component>
    )
}

export default Container
