import React, { useEffect, useState,useRef } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import Select from 'react-select';
import useFetch from 'hooks/useFetch'
import { API_parcferme } from 'constants/api.constant'
import ModalSynctime from 'components/ModalSynctime'

import { HiPencilAlt } from 'react-icons/hi';
import {
  MdFastForward, MdArrowForwardIos, MdArrowBackIos,
  MdReplay, MdPause, MdPlayArrow, MdFastRewind
} from 'react-icons/md';

export default function Videos({ className, incidente }) {
    const { id: roundId } = useParams()
    let { videop: video1, guilty_id, victim_id,sessionTime,id } = incidente;
    const [video2, setVideo2] = useState(null)
    const [video2Time, setVideo2Time] = useState(0)
    const [videoGuiltyOptions, setVideoGuiltyOptions] = useState([])
    const [playing, setPlaying] = useState(false)
    const video1Ref = useRef(null);
    const video2Ref = useRef(null);
    const [hasStartedPlaying, setHasStartedPlaying] = useState(false); // New state to track if the video has started

    const [openModal, setOpenModal] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const onOpenModal = (videoData) => {
        setSelectedVideo(videoData)
        setOpenModal(true)
    }
    const onCloseModal = () => {
        setOpenModal(false)
        setSelectedVideo(null)
    }

    const { data: fetchResponse } = useFetch(`${API_parcferme}?round_id=${roundId}`);
    const data = fetchResponse ? fetchResponse.data : null;

    const onReadyVideo2 = () => {
        // Check if the video2Ref is current and video2Time is a valid number
        if (video2Ref.current && !isNaN(video2Time)) {
            video2Ref.current.seekTo(parseFloat(video2Time), 'seconds');
        }
    };

    useEffect(() => {
        if (data) {
            const options = data.map(d => {
                return {
                    id: `${d.Piloto}-${d.Youtube1 || d.Youtube2}`,
                    value: d.Youtube1 || d.Youtube2,
                    label: d.Piloto,
                    ...d
                }
            });

            setVideoGuiltyOptions(options)

            // Set default video for video2 if guilty_id matches
            const defaultVideo2 = options.find(o => o.joomla_id === parseInt(guilty_id));
            if (defaultVideo2) {
                setVideo2(defaultVideo2);
            }

            // Set video1 if it does not exist and victim_id matches
            if (!video1) {
                const defaultVideo1 = options.find(o => o.joomla_id === parseInt(victim_id));
                if (defaultVideo1) {
                    video1 = defaultVideo1.value;
                }
            }
        }
    }, [data, guilty_id, victim_id])

    useEffect(() => {
        if (!video2 || !incidente.sessionTime) {
            setVideo2Time(0);
            return;
        }

        const secondsIncidente = parseInt(incidente?.sessionTime || 0);
        const secondsGuilty = parseInt(video2?.min_time || 0);
        const victimTime = parseInt(data?.find(d => d.joomla_id === parseInt(victim_id))?.min_time || 0);

        setVideo2Time((secondsIncidente - victimTime)+secondsGuilty);

        console.log('secondsIncidente:', secondsIncidente);
        console.log('guilty.min_time:', secondsGuilty);
        console.log('victimTime:', victimTime);
        console.log('video2Time1:', video2Time);       
    }, [incidente, video2, data, victim_id]);

    useEffect(() => {
        // Seek to new time when video2 or video2Time changes
        if (video2Ref.current && !isNaN(video2Time)) {
            video2Ref.current.seekTo(parseFloat(video2Time), 'seconds');
            setHasStartedPlaying(false); // Reset to allow for re-seeking
        }
        console.log('video2Time2:', video2Time);
    }, [video2, video2Time]);
       
    // useEffect(() => {
    //     console.log('video2Time2:', video2Time);
    // }, [video2Time]);
    
    const onChangeVideo = (selectedOption) => {
        setVideo2(selectedOption);
        // If a video is selected, play both videos
        if (selectedOption) {
            setPlaying(true);
        }
    };

    const togglePlayPause = () => {
        setPlaying(!playing);
    };

    const restartVideos = () => {
        if (video1Ref.current && video2Ref.current) {
            video1Ref.current.seekTo(parseFloat(sessionTime), 'seconds');
            video2Ref.current.seekTo(parseFloat(video2Time), 'seconds');
            setHasStartedPlaying(false); // Allow for re-seeking on subsequent plays
            setPlaying(true);
        }
    };

    const onPlay = () => {
        if (!hasStartedPlaying) {
          const video1StartTime = parseFloat(sessionTime);
          const video2StartTime = parseFloat(video2Time);
      
          if (video1Ref.current && !isNaN(video1StartTime)) {
            video1Ref.current.seekTo(video1StartTime, 'seconds');
          }
          if (video2Ref.current && !isNaN(video2StartTime)) {
            video2Ref.current.seekTo(video2StartTime, 'seconds');
          }
      
          setHasStartedPlaying(true); // Prevent future seeks on play events
        }
      
        setPlaying(true); // Start playing both videos
      };
    
    
      const onPause = () => setPlaying(false)



    useEffect(() => {
        setPlaying(true);
      }, []);
    
      // Function to handle when the first video player is ready
      const handleFirstPlayVideo1 = () => {
        setPlaying(true);
        if (!hasStartedPlaying) {
          video1Ref.current.seekTo(parseFloat(sessionTime), 'seconds');
          setHasStartedPlaying(true); // Update state to prevent future seeks on play events
        }
      };
    
      // Function to handle when the second video starts playing
      const handleFirstPlayVideo2 = () => {
        if (!hasStartedPlaying && video2Time) {
          video2Ref.current.seekTo(parseFloat(video2Time), 'seconds');
          setHasStartedPlaying(true); // Update state to prevent future seeks on play events
        }
      };
//new control functionality



const seekVideo1 = (seconds) => {
    if (video1Ref.current) {
        video1Ref.current.seekTo(video1Ref.current.getCurrentTime() + seconds);
    }
};

// Seek control functions for video2
const seekVideo2 = (seconds) => {
    if (video2Ref.current) {
        video2Ref.current.seekTo(parseInt(video2Ref.current.getCurrentTime() + seconds));
    }
};
    return (
        <>
            <div className={`grid md:grid-cols-2 gap-2 ${className}`}>
                <div>
                    {video1 ? (
            <ReactPlayer
            ref={video1Ref}
            width={'100%'}
            url={video1}
            playing={playing}
            onPlay={onPlay} // Use the combined play handler
            onPause={onPause}
            controls
          />
                    ) : (
                        <div className='flex justify-center'>
                            <p className='text-gray-500 italic'>No hay video</p>
                        </div>
                    )}
                    <div className='flex justify-center text-gray-700 mt-2'>
                        <label>Afectado:</label>
                        <p className='font-semibold ml-2'>{incidente.Afectado}</p>
                        <HiPencilAlt className='cursor-pointer ml-2 self-center' onClick={() => onOpenModal({ url: video1, user: incidente.Afectado, incidenteid: incidente.id})} />
                    </div>
                </div>

                <div>
                {video2 ? (
            <ReactPlayer
              ref={video2Ref}
              width={'100%'}
              url={video2?.value}
              playing={playing}
              onPlay={onPlay}
              onReady={onReadyVideo2} // Use the combined play handler
              onPause={onPause}
              controls
            />
                    ) : (
                        <div className='flex justify-center'>
                            <p className='text-gray-500 italic'>No hay video</p>
                        </div>
                    )}
                    <div className='flex justify-between mt-2 min-w-max'>
                        <div className='flex items-center text-gray-700'>
                            <label>Reportado:</label>
                            <p className='font-semibold ml-2'>{incidente.Reportado}</p>
                            {/* <HiPencilAlt className='cursor-pointer ml-2' onClick={() => onOpenModal({ url: video2?.value, owner: incidente.Reportado, incidenteid: incidente.id})} /> */}
                        </div>
                        <Select
                            className='w-64'
                            options={videoGuiltyOptions}
                            placeholder='--Selecciona un video--'
                            noOptionsMessage={() => 'No hay videos'}
                            onChange={onChangeVideo}
                            value={video2}
                            isClearable
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="video-controls" style={{ textAlign: 'center', margin: '20px 0' }}>
                {/* Buttons for controlling video1 */}
                <div style={buttonGroupStyle}>
                    <button onClick={() => seekVideo1(-5)} style={buttonStyle}><MdFastRewind /></button>
                    <button onClick={() => seekVideo1(-1)} style={buttonStyle}><MdArrowBackIos /></button>
                    <button onClick={() => seekVideo1(1)} style={buttonStyle}><MdArrowForwardIos /></button>
                    <button onClick={() => seekVideo1(5)} style={buttonStyle}><MdFastForward /></button>
                </div>

                <div style={dividerStyle}></div>

                {/* Play/Pause button for both videos */}
                <div style={buttonGroupStyle}>
                    <button onClick={togglePlayPause} style={buttonStyle}>
                        {playing ? <MdPause /> : <MdPlayArrow />}
                    </button>
                </div>

                <div style={dividerStyle}></div>

                {/* Restart button for both videos */}
                <div style={buttonGroupStyle}>
                    <button onClick={restartVideos} style={buttonStyle}><MdReplay /></button>
                </div>

                <div style={dividerStyle}></div>

                {/* Buttons for controlling video2 */}
                <div style={buttonGroupStyle}>
                    <button onClick={() => seekVideo2(-5)} style={buttonStyle}><MdFastRewind /></button>
                    <button onClick={() => seekVideo2(-1)} style={buttonStyle}><MdArrowBackIos /></button>
                    <button onClick={() => seekVideo2(1)} style={buttonStyle}><MdArrowForwardIos /></button>
                    <button onClick={() => seekVideo2(5)} style={buttonStyle}><MdFastForward /></button>
                </div>
            </div>
            <ModalSynctime open={openModal} onClose={onCloseModal} videoData={selectedVideo} />
        </>
    );
}

const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 15px',
    margin: '0 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const buttonGroupStyle = {
    display: 'inline-block'
};

const dividerStyle = {
    display: 'inline-block',
    width: '2px',
    height: '40px',
    backgroundColor: '#dee2e6',
    margin: '0 12px'
};

// Add hover effect for buttons
const buttonHoverStyle = {
    backgroundColor: '#0056b3'
};