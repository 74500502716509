import { auth, db } from 'config/firebase.config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { apiPostLogin, apiPostRegister } from 'services/AuthService'

/**
 * Registers and logs in a user using the provided form data.
 *
 * @param {object} form - the user's registration and login form data
 * @param {function} setForm - a function to update the form data
 * @return {object} the normalized user data after registration and login
 */
export const registerAndLogin = async (
	form,
	setForm
) => {
	const { email, password } = form

    const response = await apiPostRegister({email, password})
	.then((res) => { if(res.status === 200) login(form, setForm); return res.data })

	return response?.userdata
}



/**
 * Asynchronously logs in a user using the provided form data, sets the form state,
 * and returns the normalized user data.
 *
 * @param {object} form - the form data containing email and password
 * @param {function} setForm - the function to set the form state
 * @return {NormalizedUser} the normalized user data
 */
export const login = async (form, setForm) => {
	const { email, password } = form

	const { user } = await signInWithEmailAndPassword(auth, email, password)
	const accessToken = await user.getIdToken(true)

	setForm({
		name: '',
		email: '',
		password: ''
	})

    const response = await apiPostLogin(accessToken)
		.then(res => res.data)

	const { userdata } = response

	const normalizedUser = {
		uid: user.uid,
		email: user.email ?? '',
		claims: userdata.claims
	}

	return normalizedUser
}


export const validateRoundPermissions = async (roundId, user) => {
	const userRef = doc(db, `users`, `${user.uid}`)
	const roundSnapshot = await getDoc(userRef, `permissionsRounds`, `${roundId}`)
	.catch(error => console.error(error))
	if(roundSnapshot.exists()) {
		return roundSnapshot.data()
	}
}

export const validatePermissionsByModule = async ({module, userSession, roundId, eventId, masteradmin = false }) => {
	const userRef = doc(db, `users`, `${userSession.uid}`)
	let result;


	if (roundId) {
		result = await getDoc(doc(userRef, `permissionsRounds`, `${roundId}`))
		.catch(error => console.error(error))
	} else if (eventId) {
		result = await getDoc(doc(userRef, `permissionsEvents`, `${eventId}`))
		.catch(error => console.error(error))
	} else {
		result = await getDoc(doc(userRef, `permissionsProjects`, `${roundId}`))
	}
	if(result.exists() && !masteradmin) {
		return result.data().permissions[module]
	}
	if (masteradmin) {
		return true
	}
	throw new Error('No round or event provided')
}

export const refreshUserPermissions = async (user) => {
	 const response = await user.getIdTokenResult(true)
	 return response
}
