export const REACT_APP_BASEURL = process.env.REACT_APP_BASEURL;

export const API_URL = "https://restapi.gpesportsrd.com/api";
export const API_URL2 = "https://gpesportsrd.com/gpt/api";
export const API_URL3 = `${REACT_APP_BASEURL}`;
export const API_parcferme = `${REACT_APP_BASEURL}/parcferme`;
export const API_incidentes = `${REACT_APP_BASEURL}/incidentes`;
export const API_participants = `${REACT_APP_BASEURL}/consultaParticipants`;
export const API_preincidentes = `${REACT_APP_BASEURL}/preincidents`; 
export const API_rounddetails = "https://restapi.gpesportsrd.com/api/v1/external/getrounds"; 
// Add these lines to your existing constants file
export const API_COMPETITIONS = `${REACT_APP_BASEURL}/competitions`;
export const API_LAST_ROUNDS = `${REACT_APP_BASEURL}/lastrounds`;
export const API_ROUND_DETAILS = `${REACT_APP_BASEURL}/consultaRounds`;
export const API_seasonstatus = `${REACT_APP_BASEURL}/season_status`;
export const API_EVENTS = `${REACT_APP_BASEURL}/consultaEvents`;
export const API_V5_URl = `${REACT_APP_BASEURL}`;
export const API_parcferme_seasonstatus = `${REACT_APP_BASEURL}/season_status`;
export const API_parcferme_pending = `${REACT_APP_BASEURL}/parcferme_pending?projectround_id`;
export const API_pf_optimized = `${REACT_APP_BASEURL}/parcferme_pending/optimized`;

export const API_incident_type = `${REACT_APP_BASEURL}/types/incidentes`;
export const API_penalty_type = `${REACT_APP_BASEURL}/types/penalty`;
export const API_preraceresult = `${REACT_APP_BASEURL}/preraceresult`;
