import { useEffect, useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

export default function DropdownIndividual({ options, onChange, value, className, ...rest }) {
    const [teams, setTeams] = useState([]);
    const handleChange = (opt) => {
        onChange(opt.entireObject)
    }
    useEffect(() => {
        setTeams(options.map(x => ({
            value: x.id,
            label: x.name,
            entireObject: x
        })));
    }, [options])

    return (
        <Select
            className={classNames('w-56', className)}
            value={teams.find((p) => p.value?.toString() === value?.toString())}
            options={teams}
            onChange={handleChange}
            {...rest}
        />
    )
}