import React from 'react';
import Home from 'pages/Home';
import Rounds from 'pages/Rounds';
import Details from 'pages/Rounds/Detail';
import RoundsHome from 'pages/Rounds/Detail/Home';
import Incidentes from 'pages/Rounds/Detail/Incidentes';
import IncidentesDetail from 'pages/Rounds/Detail/Incidentes/Detail';
import ImportIncidentes from 'pages/Rounds/Detail/Incidentes/Import';
import ParcFerm from 'pages/Rounds/Detail/ParcFerm';
import GeneradorRedes from 'pages/Rounds/Detail/GeneradorRedes';
import Results from 'pages/Rounds/Detail/Results';
import Resultados from 'pages/Rounds/Detail/ResultadosFinales';
import SeasonStatus from 'pages/Rounds/Detail/SeasonStatus';
import { Login } from 'pages/Login/index';
import { Register } from 'pages/Register';
import { ProtectedRoles } from 'components/routes/ProtectedRoles';
import { ProtectedAuth } from 'components/routes/ProtectedAuth';
import { Logs } from 'pages/logs';

const roundsRoutes = {
    key: 'roundsDetails',
    path: '/rounds/:id',
    element: <Details />,
    children: [
        { key: 'roundsDetailsHome' , element: <RoundsHome />, index: true, },
        { key: 'roundsDetailsImportresults' , path: 'importresults', element: <Results /> },
        { key: 'roundsDetailsParcferm' , path: 'parcferm', element: <ParcFerm/> },
        { key: 'roundsDetailsIncidentes' , path: 'incidentes', element: <Incidentes />, },
        { key: 'roundsDetailsIncidentes' , path: 'incidentes/import', element: <ImportIncidentes />, },
        { key: 'roundsDetailsIncidentes' , path: 'incidentes/:incidenteId', element: <IncidentesDetail />, },
        { key: 'roundsDetailsResultados' , path: 'resultados', element: <Resultados /> },
        { key: 'roundsDetailsGrs' , path: 'grs', element: <div><GeneradorRedes/></div> },
        { key: 'roundsDetailsSeason' , path: 'SeasonStatus', element: <div><SeasonStatus/></div> },
    ]
};


export const routes = [
    { key: 'home', path: '/', element: <Home />, },
    { key: 'rounds', path: '/rounds', element: <ProtectedAuth><Rounds /></ProtectedAuth> },
    { key: 'roles', path: '/roles', element: <ProtectedRoles/>, },
    { key: 'login', path: '/login', element: <ProtectedAuth> <Login /> </ProtectedAuth>, },
    { key: 'register', path: '/register', element: <ProtectedAuth> <Register /> </ProtectedAuth>, },
    { key: 'logs', path: '/logs', element: <ProtectedAuth> <Logs /> </ProtectedAuth>, },
    roundsRoutes
];