
import { StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
      flexDirection: 'row',
      padding: 10,
  },
  section: {
      margin: 10,
  },
  header: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 10,
  },
  img: {
      width: 550,
  },
  title: {
      fontSize: 20,
      fontFamily: 'f1tv-namecondensed-bold',
  },
  subtitle: {
      fontSize: 14,
      fontFamily: 'f1tv-namecondensed-bold',
  },
  card: {
      padding: 10,
      width: 550,
  },
  cardContent: {
      flexDirection: 'column',
      fontSize: 14,
  },
  cardText: {
      fontStyle: 'bold',
      marginBottom: 1,
  }
});

export const tableStyles = StyleSheet.create({
  table: {
    width: 550,
    fontSize: 12,
    marginTop: 10,
    marginBottom: 20,
  },
  tHead: {
    backgroundColor: '#f9fafb',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
  },
  th: {
    width: '25%',
    borderRight: 1,
    borderRightWidth: 1,
    borderRightColor: '#fff',
    textAlign: 'center',
  },
  tBody: {
    flexDirection: 'column',
  },
  td: {
    width: '25%',
    borderRight: '1px solid #fff',
    textAlign: 'center',
    height: 30,
  },
  tr: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
  },
  rankEqual: {
    fontWeight: 600,
    color: '#4b5563',
  },
  rankUp: {
    fontWeight: 600,
    color: '#10b981',
  },
  rankDown: {
    fontWeight: 600,
    color: '#ef4444',
  },
});

export default styles;
