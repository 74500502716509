import { useState, createContext, useEffect, useContext} from 'react'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { apiLogout } from 'services/AuthService';

const Context = createContext()

export function UserContextProvider({ children }){
    const [userSession, setUserSession] = useState(undefined)
    const auth = getAuth();

    
    const logOut = () => {
      signOut(auth)
          .then(() => {
              apiLogout()
              setUserSession(null);
          })
          .catch((error) => {
              alert.error(`Error inesperado al cerrar la sesión: ${error.message}. 
                          Intente recargar la página o contacte a un administrador`);
          });
  };
  

      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            user.getIdTokenResult()
              .then((idTokenResult) => {
                setUserSession((prevState) => ({
                  ...prevState,
                  ...user,
                  claims: idTokenResult.claims,
                }));
              })
              .catch((error) => {
                alert.error(`Error inesperado al recuperar la sesión: ${error.message}. 
                            Intente recargar la página o contacte a un administrador`);
              });
          } else {
            setUserSession(null);
          }
        });
    
        return () => {
          unsubscribe();
        };
      }, [auth]);

      return (
          <Context.Provider value={{ userSession, setUserSession, logOut }}>
            {children}
          </Context.Provider>
      );
}

export const useUserSession = () => useContext(Context)
