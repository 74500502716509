import { TableLog } from "components/TableLog";
import { useEffect, useState } from "react";
import { useUserSession } from "userContext";
import { getLogs } from "utils/logsUtils";


export function Logs() {
    const [logs, setLogs] = useState([])

    const { user } = useUserSession();

    useEffect(() => {
        getLogs()
        .then(logs => setLogs(logs.sort((a, b) => b.date - a.date)))
        .catch(error => console.error(error))
        
    }, [])




    return (
        <div>
            <TableLog logs={logs}/>
        </div>
    )
}