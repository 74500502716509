import React from 'react'
import Menu from './Menu'
import { HiBars3, HiBars3BottomRight } from 'react-icons/hi2'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Button } from 'components/ui/Button'
import { useUserSession } from 'userContext'

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const { userSession, logOut } = useUserSession()

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    return (
        <nav>
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl">
                <Link to="/" className="flex items-center">
                    <img src="/logo512.png" className="mr-3 h-6 sm:h-9" alt="GP Logo" />
                    <span className="self-center text-xl font-semibold whitespace-nowrap">GPEsport</span>
                </Link>
                <div className="flex items-center md:order-2 md:hidden">
                    <button variant='outline' color='gray' onClick={toggleMenu} className='p-2 md:hidden ml-2'>
                        <span className="sr-only">Open main menu</span>
                        {
                            isMenuOpen ? (
                                <HiBars3 className="w-6 h-6" />
                            ) : (
                                <HiBars3BottomRight className="w-6 h-6" />
                            )
                        }
                    </button>
                </div>
                <div
                    className={classNames(
                        'justify-between items-center w-full md:flex md:w-auto md:order-1',
                        {
                            'hidden': !isMenuOpen,
                            'flex': isMenuOpen
                        }
                    )}
                >
                    <Menu className='flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0 w-full' />
                    {
                        userSession &&  <Button className='mx-3 text-lg' onClick={logOut}>Salir</Button>
                    }
                </div>
            </div>
        </nav>
    )
}
