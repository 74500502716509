// utils/rounddetails.js
import { useEffect, useState } from 'react';
import { API_rounddetails } from 'constants/api.constant'; // Import the constant

export function useRoundDetails(roundId) {
    const [round, setRound] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${API_rounddetails}?round_id=${roundId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }); // Use the constant here
                if (!response.ok) {
                    const errorMessage = await response.text();
                    throw new Error(errorMessage);
                }
                const data = await response.json();
                setRound(data[0]);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching round details:', error);
                setError(error);
                setLoading(false);
            }
        }

        fetchData();
    }, [roundId]);

    return { round, loading, error };
}
