const PERSIST_STORAGE_NAME = 'gpesports';

export const saveToLocalStorage = (key, value) => {
  const currentValue = JSON.parse(localStorage.getItem(`${PERSIST_STORAGE_NAME}`));
  const newValue = setNestedValue(currentValue, key, value);
  localStorage.setItem(`${PERSIST_STORAGE_NAME}`, JSON.stringify(newValue));
};

export const getFromLocalStorage = (key) => {
  const currentValue = JSON.parse(localStorage.getItem(`${PERSIST_STORAGE_NAME}`));
  return getNestedValue(currentValue, key);
};

const setNestedValue = (object, key, value) => {
  const keys = key.split('.');
  let currentObject = object || {};

  for (let i = 0; i < keys.length - 1; i++) {
    const currentKey = keys[i];
    currentObject[currentKey] = currentObject[currentKey] || {};
    currentObject = currentObject[currentKey];
  }

  currentObject[keys[keys.length - 1]] = value;

  return { ...object };
};

const getNestedValue = (object, key) => {
  const keys = key.split('.');
  let currentObject = object;

  for (let i = 0; i < keys.length; i++) {
    if (!currentObject || typeof currentObject !== 'object') {
      return undefined;
    }
    currentObject = currentObject[keys[i]];
  }

  return currentObject;
};

const Storage = {
  saveToLocalStorage,
  getFromLocalStorage,
};

export default Storage;