import classNames from 'classnames';
import React from 'react'

export default function Table(props) {
    const { children, className, title, ...rest } = props;
    return (
        <div className={`relative shadow-md sm:rounded-lg mb-6 ${className}`} {...rest}>
            {title && (
                <h2 className='text-2xl font-bold px-3 py-3'>
                    {title}
                </h2>
            )}
            <table className='w-full text-sm text-left text-gray-500'>
                {children}
            </table>
        </div>
    )
}

export function THead(props) {
    const { children, className, ...rest } = props;
    return (
        <thead className={`text-xs text-gray-700 bg-gray-50 ${className}`} {...rest}>
            {children}
        </thead>
    )
}

export function Th(props) {
    const { children, className, ...rest } = props;
    return (
        <th scope='col' className={classNames('px-6 py-3', className)} {...rest}>
            {children}
        </th>
    )
}

export function TBody(props) {
    const { children,className, ...rest } = props;
    return (
        <tbody className={`${className}`} {...rest}>
            {children}
        </tbody>
    )
}

export function Td(props) {
    const { children, className, ...rest } = props;
    return (
        <td className={`px-6 py-4 ${className}`} {...rest}>
            {children}
        </td>
    )
}

export function Tr(props) {
    const { children, className, ...rest } = props;
    return (
        <tr className={className} {...rest}>
            {children}
        </tr>
    )
}

export function LoadingTableContent (props) {
    const { colSpan, className, loading, ...rest } = props;
    return loading && (
        <Tr>
            <Td colSpan={colSpan} className={`text-center ${className}`} {...rest}>
                Cargando...
            </Td>
        </Tr>
    )
}

export function NoDataRow (props) {
    const { colSpan, className, show, text, ...rest } = props;
    return show && (
        <Tr>
            <Td colSpan={colSpan} className={`text-center ${className}`} {...rest}>
                {text || 'No hay resultados'}
            </Td>
        </Tr>
    )
}
