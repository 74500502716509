import { HiSave, HiX } from 'react-icons/hi'
import Modal from './Modal'
import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';

const getTimeFromUrl = (url) => {
  if (!url) return 0;
  const time = url.split('t=')[1];
  if (!time) return 0;
  
  console.log( parseInt(time));
  return parseInt(time);
}

const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default function ModalSynctime({ open, onClose, videoData }) {
  const [synctimeActual, setSynctimeActual] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);

  const onProgress = (progress) => {
    setElapsedTime(progress.playedSeconds.toFixed(2));
  }

  useEffect(() => {
    // Log the entire videoData object to the console
    console.log('videoData:', videoData);
    setSynctimeActual(getTimeFromUrl(videoData?.url));
  }, [videoData]);

  const handleUpdateClick = async () => {
    // Ensure elapsedTime is an integer
    const updatedSessionTime = parseInt(elapsedTime, 10);

    try {
      const response = await fetch(`https://api4.gpesportsrd.com/incidentes/${videoData?.incidenteid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Include other headers like authorization if needed
        },
        body: JSON.stringify({ sessionTime: updatedSessionTime }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Optionally, handle the response data
      const responseData = await response.json();
      console.log('Update successful:', responseData);

      // Close the modal on successful update
      onClose();

      window.location.reload();
    } catch (error) {
      console.error('Update failed:', error);
      // Do not close the modal on failure
    }
  };


  return (
    <Modal open={open} onClose={onClose}>
      <div className='mb-2'>
        <h2 className='text-2xl font-bold'>Ajuste Momento Incidente</h2>
        <p className='text-gray-500 text-sm'>Pause el vídeo segundos antes del incidente.</p>
      </div>

      <div className=''>
        <label className='text-gray-700'>Usuario:</label> {videoData?.user} 
      </div>

      <div className='mb-2'>

        <ReactPlayer
          width={'100%'}
          height={300}
          url={videoData?.url}
          controls
          onProgress={onProgress}
        />
      </div>

      <div className='flex gap-2 mb-4'>
        <div>
          <label className='text-gray-700'>Momento Incidente:</label>
          <input
            type='text'
            className='w-full border rounded-md px-3 py-2 mt-1 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
            placeholder='Synctime actual'
            value={formatTime(synctimeActual)}
            readOnly
          />
        </div>
        <div>
          <label className='text-gray-700'>Momento Actualizado:</label>
          <input
            type='text'
            className='w-full border rounded-md px-3 py-2 mt-1 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
            placeholder='Tiempo del video'
            value={formatTime(elapsedTime)}
            readOnly
          />
        </div>
      </div>

      <div className=''>
        <button type='button' onClick={onClose} className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded mr-2'>
          <span className='flex items-center gap-1'>
            <HiX className='text-lg' /> Cancelar
          </span>
        </button>
        <button
          type='button'
          onClick={handleUpdateClick}
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded'
        >
          <span className='flex items-center gap-1'>
            <HiSave className='text-lg' /> Actualizar
          </span>
        </button>
      </div>
    </Modal>
  )
}
