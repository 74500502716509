import React from 'react'
import { API_URL2 } from 'constants/api.constant';
import useFetch from 'hooks/useFetch';
import Select from 'react-select';

// Para mostrar la imagen en el select
// import { HiCheck } from 'react-icons/hi2';

// const { Control } = components

// const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
//     return (
//         <div
//             className={`flex items-center justify-between p-2 ${
//                 isSelected
//                     ? 'bg-gray-100'
//                     : 'hover:bg-gray-50'
//             }`}
//             {...innerProps}
//         >
//             <div className="flex items-center">
//                 <img src={data.imgPath} alt={data.label} className='rounded-full w-6 h-6' />
//                 <span className="ml-2 rtl:mr-2">{label}</span>
//             </div>
//             {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
//         </div>
//     )
// }

// const CustomControl = ({ children, ...props }) => {
//     const selected = props.getValue()[0]
//     return (
//         <Control {...props}>
//             {selected && (
//                 <img src={selected.imgPath} alt={selected.label} className='rounded-full w-6 h-6 ml-1' />
//             )}
//             {children}
//         </Control>
//     )
// }

const penaltyTypesEndpoint = '/getpenaltytypes.php';
export default function SelectPenaltyType({value,disabled, ...rest}) {
    const { data: penaltyTypes } = useFetch(`${API_URL2}${penaltyTypesEndpoint}`);
    const penaltyOptions = penaltyTypes.length > 0 ? penaltyTypes?.map(p => ({ value: p.id, label: p.text, imgPath: p.emoji })) : [];

    return (
        <Select
        isDisabled={disabled}
            options={penaltyOptions}
            // components={{ Option: CustomSelectOption, Control: CustomControl }}
            value={penaltyOptions.find(p => p.value === value)}
            {...rest}
        />
    )
}
