import { db } from "config/firebase.config";
import { addDoc, collection, getDocs } from "firebase/firestore"

export const createLog = (log) => {
    try {
        const logRef = collection(db, 'logs');
        addDoc(logRef, log) 
    } catch (error) {
        console.error(error.message)
    }
}

export const getLogs = async () => {
    const logRef = collection(db, 'logs');
    const logsSnapshot = await getDocs(logRef)
    const logs = logsSnapshot?.docs?.map(doc => doc?.data())
    return logs
}