import React from 'react'
import Table, { TBody, Td, Th, THead, Tr } from 'components/Table';
import { Link, useLocation } from 'react-router-dom';

export default function IncidentesList({ data, loading }) {
    const location = useLocation();
    return (
        <Table id='tabla-incidentes'>
            <THead>
                <Tr>
                    <Th style={{ padding: 0}}></Th>
                    <Th>Lap</Th>
                    <Th>Fecha</Th>
                    <Th>Afectado</Th>
                    <Th>Tiempo</Th>
                    <Th>Reportado</Th>
                    <Th>Incidente</Th>
                    <Th>Resolucion</Th>
                    <Th>Auth</Th>
                    <Th>Vis</Th>
                </Tr>
            </THead>
            <TBody>
                {data.map((incidente, index) => (
                    <Tr key={index} className='hover:bg-gray-100 relative'>
                        <Th style={{ padding: 0}}>
                            <Link to={`${location.pathname}/${incidente.id}`} className='absolute inset-0 z-10' />
                        </Th>
                        <Td>{incidente.Lap}</Td>
                        <Td>{incidente.Fecha}</Td>
                        <Td>{incidente.Afectado}</Td>
                        <Td>{incidente.Tiempo}</Td>
                        <Td>{incidente.Reportado}</Td>
                        <Td>{incidente.Incidente}</Td>
                        <Td>{incidente.Resolucion}</Td>
                        <Td>{incidente.Auth}</Td>
                        <Td>{incidente.visibility}</Td>
                    </Tr>
                ))}
                {
                    !loading && data.length === 0 && (
                        <Tr>
                            <Td colSpan={10} className='text-center'>
                                No hay incidentes
                            </Td>
                        </Tr>
                    )
                }
            </TBody>
        </Table>
    )
}
