import React from 'react'
import Container from './Container'
import { Outlet } from 'react-router-dom'
import Header from './Header'

function Layout() {
  return (
    <>
        <Header />
        <Container className='mt-4 px-4 sm:p-0'>
            <Outlet />
        </Container>
    </>
  )
}

export default Layout