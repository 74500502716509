import { useEffect, useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

export default function DropdownIndividual({ options, onChange, value, className, ...rest }) {
    const [participants, setParticipants] = useState([]);
    const handleChange = (opt) => {
        onChange(opt.entireObject)
    }
    useEffect(() => {
        setParticipants(options.map(x => ({
            value: x.individual_id,
            label: `${x.first_name || ''} ${x.last_name || ''} - ${x.nickname || ''} - ${x.Eagame || ''}`,
            entireObject: x
        })));
    }, [options])

    return (
        <Select
            className={classNames('w-56', className)}
            value={participants.find((p) => p.value?.toString() === value?.toString())}
            options={participants}
            onChange={handleChange}
            {...rest}
        />
    )
}