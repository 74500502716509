import React from "react";
import { HiCalendar } from "react-icons/hi2";

export default function OverviewRound(props) {
    const { round } = props;

    return (
        <div className='flex mb-2'>
            <div>
                <h2 className='text-2xl font-bold'>{round.projectname} - {round.track_name}</h2>
                <p className='text-gray-600 text-sm flex items-center font-semibold'>
                    <HiCalendar className='mr-1' />
                    <span>{new Date(round.start_date).toLocaleString()}</span>
                </p>
            </div>
        </div>
    )
}