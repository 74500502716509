import { useState } from "react";
import Modal from "./Modal";
import { RoleSelect } from "./RoleSelect";
import { Button } from "./ui/Button";
import Select from 'react-select';
import { createOrUpdateUserPermissionsAndRoles } from "utils/roleUtils";
import { LoadingSpinner } from "./ui/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { refreshUserPermissions } from "utils/authUtils";
import { auth } from "config/firebase.config";
import { createLog } from "utils/logsUtils";
import PermissionsByRole from "./ui/PermissionsList";

export function AddUser({ open, onClose, projectId, competitionName, users}) {
    const [newUser, setNewUser] = useState({
        role: '',
        userUid: '',
        projectId,
        affectedEmail: ''
    });


    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const user = auth.currentUser

    const handleAddUser = async (e) => {
        e.preventDefault()
        setLoading(true)

        createOrUpdateUserPermissionsAndRoles({newUser, projectId})
        .then(() => {
            setLoading(false)
        })
        .then(() => createLog({authorEmail: user.email, action: `Añadio un usuario a ${competitionName}`, projectId: newUser?.projectId, affected: newUser.affectedEmail, date: new Date()}))
        .then( () => refreshUserPermissions(user))
        .then(() => navigate(0))
        .catch(error => {
            setLoading(false);
            console.error('Error al agregar usuario:', error);
            alert('Error al agregar usuario:', error);
        });

    }

    return (
        <>
            <LoadingSpinner loading={loading}/> 
            <Modal className="flex justify-center " open={open} onClose={onClose}>
                <h1>Agregar usuario a {competitionName} (ProjectID: {projectId})</h1>
                <form  onSubmit={handleAddUser} className='flex flex-col justify-center align-middle items-center md:grid grid-cols-2'>
                    <div className="flex w-full flex-col gap-3 p-4">
                        <Select
                            options={users.map(user => ({
                                value: user.uid,
                                label: user.email
                            }))}
                            className="w-full"
                            closeMenuOnSelect={true}
                            onChange={(e) => setNewUser({ ...newUser, userUid: e.value, affectedEmail: e.label }) }
                            />
                            <RoleSelect handleRoleChange={(e) => setNewUser({ ...newUser, role: e })} ></RoleSelect>
                    </div>
                    <div className="flex flex-col gap-3 p-4">
                        <Button>Aplicar</Button>
                    </div>
                </form>
                <div className="flex flex-col w-1/2"> 
                    <PermissionsByRole role={newUser.role}></PermissionsByRole>
                </div>
            </Modal>
        </>
    )
}