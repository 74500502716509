import { AddUser } from "components/AddUser";
import TableRoles from "components/TableRoles";
import { Button } from "components/ui/Button";
import { useCallback, useEffect, useState } from "react";
import Select from 'react-select';
import { apiGetUsers } from "services/AuthService";
import { apiGetRounds } from "services/RoundService";

export default function Roles() {
  const [isOpen, setIsOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [competition, setCompetition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectSelected, setProjectSelected] = useState('');



  const fetchUsers = useCallback(() => {
    return apiGetUsers().then(({data}) => {
      setAllUsers(data.users);
      return data
    })
  },[])


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await apiGetRounds();
        const rounds = response?.data;

        const uniqueProjects = [];
        const projectNames = new Set();
        
        rounds.forEach(round => {
          if (!projectNames.has(round.projectname)) {
            projectNames.add(round.projectname);
            uniqueProjects.push({
              label: round.projectname,
              value: round.project_id
            });
          }
        });

        setProjects(uniqueProjects);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []);

  const handleSearch = useCallback((query) => {
    setQuery(query);
  }, []);


  useEffect(() => {
    if(projectSelected !== '' || query !== '') {
      const filteredUsers = allUsers?.filter(user => {
        const projectNameMatches = !projectSelected || user?.customClaims?.projects?.some(project => project?.projectId === projectSelected);
        const queryMatches = user?.email?.toLowerCase().includes(query.toLowerCase());
        return projectNameMatches && queryMatches;
      });
      
      setUsers(filteredUsers);
    }

    if(!projectSelected && query === '') {
      setUsers(allUsers);
    }
    

  }, [allUsers, query, projectSelected]);

  const handleRoleChange = (newRole, userId) => {
    setLoading(true);
    setUsers(prevUsers => prevUsers.map(user =>
      user.id === userId ? { ...user, role: newRole } : user
    ));

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (allUsers.length === 0) {
      fetchUsers()
      .then(
        data => {
          setAllUsers(data.users);
          setUsers(data.users);
        }
      )
    }
  }, [allUsers.length, fetchUsers]);

  return (
    <div className="p-4 h-full">
      <h1 className="text-2xl font-bold">Roles</h1>
      <span className="my-2 flex flex-col md:flex-row gap-4 items-center">
        Buscar usuarios:
        <input
          className="w-1/2 my-2 p-2 border border-gray-300 rounded"
          placeholder="Nombre de usuario"
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="flex flex-row gap-4">
          <Select
            options={projects}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable
            isSearchable
            placeholder='Competition'
            className="z-10"
            value={projects?.find(project => project.value === projectSelected)}
            onChange={(selectedOption) => {
              setProjectSelected(selectedOption?.value);
              setCompetition(selectedOption?.label);
            }}
          ></Select>
          <Button disabled={!projectSelected} onClick={() => setIsOpen(true)}>Añadir usuario</Button>
        </div>
        {isOpen && <AddUser handleRoleChange={handleRoleChange} projectId={projectSelected} competitionName={competition} users={allUsers} open={isOpen} onClose={() => setIsOpen(false)} />}
      </span>
      <h1>Estas administrando: {competition ? competition : 'Todas las competencias'}</h1>

      <TableRoles
        projectSelected={projectSelected}
        query={query}
        handleSearch={handleSearch}
        setUsers={setUsers}
        users={users}
        loading={loading}
        setLoading={setLoading}
        selectedRole={selectedRole}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        projectId={projectSelected}
        projectName={competition}
      />
    </div>
  );
}
