import { useState } from "react";
import { ModalRoles } from "./ModalRoles";
import Table, { LoadingTableContent, NoDataRow, TBody, THead, Td, Th, Tr } from "./Table";
import { Button } from "./ui/Button";

export default function  TableRoles({ users, loading, selectedRole, projectId, projectName, projectSelected}) {

  const [modalUser, setModalUser] = useState(null);

  const filterUserRoleOrProjects = (user, projectSelected, projectId) => {
    if (projectSelected) {
        const project = user?.customClaims?.projects?.find(project => project.projectId === projectId);
        return project ? project.role : null;
    } else {
        return user?.customClaims?.projects?.map(project => project.projectId).join(', ');
    }
};

  const handleCloseModal = () => {
    setModalUser(null);
  };

    return (
      <>
        <Table className='min-h-max'>
          <THead>
            <Tr>
              <Th style={{ padding: 0}}></Th>
              <Th>Email</Th>
              <Th>{projectSelected ? 'Rol' : 'Proyectos'}</Th>
              <Th>Acciones</Th>
            </Tr>
          </THead>
          <TBody className='bg-b'>
            <LoadingTableContent colSpan='4' loading={loading} />
            { users && users?.map((user, index) => (
              <Tr className='hover:bg-gray-100 relative' key={index}>
                <Th style={{ padding: 0}}>
                </Th>
                <Td>{user?.email}</Td>
                <Td className='grid grid-cols-3 border-none'>{filterUserRoleOrProjects(user, projectSelected, projectId)}</Td>
                <Td>
                    <Button  disabled={projectId === undefined || projectId === ''} onClick={ () => setModalUser(user)}>+</Button>
                </Td>
              </Tr>
            ))}
            <NoDataRow show={!loading && users.length === 0} colSpan='4' />
          </TBody>
        </Table>
          {modalUser && (
            <ModalRoles open={true} onClose={handleCloseModal} projectId={projectId} projectName={projectName} user={modalUser} selectedRole={selectedRole} />
            )}
      </>
    )
}