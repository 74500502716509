import Select from 'react-select';

export function RoleSelect({ userRole, handleRoleChange }) {


    return (
        <div className='flex flex-col p-2 gap-4'>
            <Select
                options={[
                    { value: 'admin', label: 'Admin' },
                    { value: 'director', label: 'Director' },
                    { value: 'juez', label: 'Juez' },
                    { value: 'colaborador', label: 'Colaborador'},
                    { value: 'invitado', label: 'Invitado'},
                ]}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isSearchable
                placeholder={ userRole ? userRole : 'Rol'}
                onChange={(e) => handleRoleChange(e.value)}
            ></Select>
            
        </div>

        
    )
}