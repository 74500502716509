import React, { useState } from 'react'
import { HiPlusSm, HiSave, HiX } from 'react-icons/hi';
import classNames from 'classnames';
import SelectPenaltyType from '../SelectPenaltyType';

export default function VotosForm({ className, onSubmit }) {
    const [ isCreating, setIsCreating ] = useState(false);
    const [ values, setValues ] = useState({});

    const onCreate = () => {
        setIsCreating(true);
    }

    const onCancel = () => {
        setIsCreating(false);
        setValues({});
    }

    const onChange = (e) => {
        setValues({
            ...values,
            [e.target.id]: e.target.value
        });
    }

    const onChangeSelect = (e, name) => {
        console.log(e);
        setValues({
            ...values,
            [name]: e
        });
    }

    const buttonCreateClasses = classNames(
        'bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded', {
        'hidden': isCreating
    });

    const onSubmitForm = (e) => {
        e.preventDefault();
        onSubmit(values, { setIsCreating });
    }

    return (
        <div className={className}>
            <div className='flex items-center justify-between'>
                <h3 className='text-2xl font-bold mb-2'>Votos</h3>
                <button className={buttonCreateClasses} onClick={onCreate}>
                    <span className='flex items-center'>
                        <HiPlusSm className='text-2xl' /> Nuevo Voto
                    </span>
                </button>
            </div>

            {isCreating && (
                <div className=''>
                    <form onSubmit={onSubmitForm}>
                        <div className=' flex justify-between gap-4 mb-2'>
                        <div className='w-full'>
                            <label htmlFor='penalty' className='block text-sm font-medium text-gray-700'>Penalty Type</label>
                            <SelectPenaltyType
                                id='penalty'
                                placeholder='--Select a penalty type--'
                                value={values.penalty}
                                onChange={(e) => onChangeSelect(e, 'penalty')}
                                required
                            />
                        </div>
                        <div className='w-full'>
                            <label htmlFor='comentario' className='block text-sm font-medium text-gray-700'>Comentario</label>
                            <input
                                id='comentario'
                                className='block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                                placeholder='Comentario'
                                onChange={onChange}
                            />
                        </div>
                        </div>
                        <div className='mb-2'>
                            <button type='button' onClick={onCancel} className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded mr-2'>
                                <span className='flex items-center gap-1'>
                                    <HiX className='text-lg' /> Cancelar
                                </span>
                            </button>
                            <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded'>
                                <span className='flex items-center gap-1'>
                                    <HiSave className='text-lg' /> Guardar
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}
