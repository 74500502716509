import axios from "axios";
import { useCallback } from "react";

export default function useAxios() {

    const apiRequest = useCallback(async(config) => {
        const { url, method, data, headers } = config;

        if (!url) {
            throw new Error("URL is required");
        }

        const isNewApi = !(url?.includes('gpesportsrd.com/gpt/') || url?.includes('gpesportsrd.com/api') || url?.includes('restapi.gpesportsrd.com'));

        return await axios({
            url,
            method: method || "GET",
            data: data || null,
            headers: headers || null,
            withCredentials: isNewApi
        });
    }, []);

    return apiRequest;
}
