import axios from "axios";

const ApiService = {
    async fetchData(params, options) {
        return await axios(params, options);
    },

    async wellFetchData (params, options) {
      return await axios(params, options)
        .then((response) => {
          return response.data;
        })  
    },
    
    async postData(url, data, params) {
        return await axios.post(url, data, params);
    },

    async putData(url, data ,params) {
        return await axios.put(url, data, params);
    }
};



export default ApiService;
