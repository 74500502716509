import React, { useEffect } from 'react'
import Table, { LoadingTableContent, NoDataRow, TBody, THead, Td, Th, Tr } from 'components/Table'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useUserSession } from 'userContext';
import { auth } from 'config/firebase.config';

export default function RoundList({ data, loading }) {
  const location = useLocation();

  const { userSession } = useUserSession()

  const user = auth.currentUser

  useEffect(() => {
    user?.getIdTokenResult(true)
    
  },[user])


  const projectsId = userSession?.claims?.projects?.map(project => project.projectId) ?? []


  const dataFiltered = userSession?.claims?.masteradmin ? data : data.filter(round => projectsId?.includes(round.project_id))


  if(!userSession){
    return <Navigate to='/' />
  }

  return (
    <Table>
      <THead>
        <Tr>
          <Th style={{ padding: 0}}></Th>
          <Th>Fecha</Th>
          <Th>Categoria</Th>
          <Th>Track Name</Th>
        </Tr>
      </THead>
      <TBody>
        <LoadingTableContent colSpan='4' loading={loading} />
        {dataFiltered.map((round, index) => ( 
          <Tr className='cursor-pointer hover:bg-gray-100 relative' key={index}>
            <Th style={{ padding: 0}}>
             <Link to={`${location.pathname}/${round.round_id}`} className='absolute inset-0 z-10' />
            </Th>
            <Td>{new Date(round.start_date).toLocaleString()}</Td>
            <Td>{round.projectname}</Td>
            <Td>{round.track_name}</Td>
          </Tr>
        ))}
        <NoDataRow show={!loading && data.length === 0} colSpan='4' />
      </TBody>
    </Table>
  )
}
