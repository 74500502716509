import { Input } from "components/forms/Input";
import { Button } from "components/ui/Button";
import { db } from "config/firebase.config";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useUserSession } from "userContext";
import { registerAndLogin } from "utils/authUtils";
import { validateEmail, validatePassword, validateRepeatPassword } from "utils/validation";

export function Register() {
    const [form, setForm] = useState({
        email: '',
        password: '',
        repeatPassword: ''
    })

    const { setUserSession } = useUserSession();

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        registerAndLogin(form, setForm)
        .then( async (user) => {
            const userRef = doc(db,'users', `${user.uid}`);
            setUserSession(user)
            await setDoc(userRef, {email: user.email, uid: user.uid})
            })
        .catch((error) => {
            if (error.response.data.error === 'The email address is already in use by another account.') {
                return alert('El correo ya se encuentra en uso por otra cuenta. Por favor ingrese otro');
            }
            alert(`Error al registrar usuario: ${error.message}`);    
            console.log('error', error);
        })
        
    }

    return (
        <form onSubmit={onSubmit} className="flex border border-b-gray-300 gap-4 justify-center align-middle items-center h-[60vh] flex-col">
            <Input label="Email:" type="email" placeholder={"Ingrese su correo"} name="email" onChange={handleChange} validation={validateEmail} errorText="Email invalido"/>
            <Input label="Password:" type="password" placeholder={"Ingrese su contraseña"}  name="password" onChange={handleChange} validation={validatePassword} errorText="Contraseña invalida"/>
            <Input label="Repeat Password:" type="password" placeholder={"Confirme su contraseña"} name="repeatPassword" onChange={handleChange} validation={validateRepeatPassword(form.password)} errorText="Las contraseñas no coinciden" />
            <Button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-2 rounded" disabled={form.password !== form.repeatPassword || !form.email || !form.password} >Registrarse</Button>
        </form>
    )
}