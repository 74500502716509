import classNames from 'classnames';
import Table, { LoadingTableContent, NoDataRow, TBody, THead, Td, Th, Tr } from 'components/Table'
import { API_URL, API_URL2, API_URL3 } from 'constants/api.constant';
import useAxios from 'hooks/useAxios';
import useFetch from 'hooks/useFetch';
import React, { useCallback, useEffect, useState } from 'react'
import { BiLoaderAlt } from 'react-icons/bi';
import { HiArrowSmDown, HiArrowSmUp } from 'react-icons/hi';
import { HiMinus } from 'react-icons/hi2';
import { quickSortResults } from 'utils';

const renderRankChange = (rank, newRank) => {
  const rankChange = rank - newRank;
  if (rankChange === 0) return <span className='font-semibold text-gray-600'><HiMinus /></span>;
  if (rankChange < 0) return <span className='flex items-center font-semibold text-red-500'>{rankChange} <HiArrowSmDown /></span>;
  if (rankChange > 0) return <span className='flex items-center font-semibold text-green-500'>{rankChange} <HiArrowSmUp /></span>;
}

const InputPenaltie = ({ ...rest }) => {
  return (
    <input
      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none w-24 text-right'
      type='number'
      step='any'
      {...rest}
    />
  )
}

const resultsByEventIdEndpoint = 'v1/external/getResultsByEventId';
const teamIdEndpoint = '/teams.php';
const TeamName = ({ teamId }) => {
  const apiRequest = useAxios();
  const [teamName, setTeamName] = useState('...');

  useEffect(() => {
    const getTeamName = async (teamId) => {
      const resp = await apiRequest({url: `${API_URL2}/${teamIdEndpoint}?teamid=${teamId}`});
      return resp?.data[0]?.short_name || 'N/A';
    }

    getTeamName(teamId).then((name) => setTeamName(name));
  }, [apiRequest, teamId]);

  return <span>{teamName}</span>
}

const preRaceResultsURL = `${API_URL3}/preraceresult`;
export default function ResultadosFinalesList({ eventId, onSave, saving }) {
  const apiRequest = useAxios();
  const [data, setData] = useState([]);
  const { data: preraceresults } = useFetch(`${preRaceResultsURL}?eventID=${eventId}`);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  // const onChangePenaltie = (index, value) => {
  //   if (value === '') value = 0;
  //   setResults((prev) => {
  //     const newResults = [...prev];
  //     newResults[index].penaltie = parseFloat(value);
  //     newResults[index].finalracetime = parseFloat(newResults[index]?.totalracetime || 0) + parseFloat(value);
  //     return newResults;
  //   });
  // }

const onChangePenaltie = (index, value) => {
  setResults((prev) => {
    const newResults = [...prev];
    newResults[index] = {
      ...newResults[index],
      penaltie: parseFloat(value),
      finalracetime: parseFloat(newResults[index]?.totalracetime || 0) + parseFloat(value),
    };
    return newResults;
  });
};


  const onKeyPressPenaltie = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  const onBlurPenaltie = () => {
    const sortedResults = quickSortResults(results);
    const newResults = sortedResults.sort((a, b) => b.laps - a.laps).map((item, index) => {
      return {
        ...item,
        newRank: index + 1,
      }
    });

    setResults(newResults.sort((a, b) => b.laps - a.laps));
    // setResults(newResults.sort((a, b) => a.rank - b.rank));
  }
  useEffect(() => {
    const mappedResults = data.map(item => ({
      ...item,
      originalPenalty: item.penaltie, // Store the original penalty value
      penaltie: item.penaltie, // This will be the editable value
      // Other properties as before
    }));
  
    // Set up your initial results state with these mapped results
    setResults(mappedResults);
  }, [data]);
  
  useEffect(() => {
    const resultsWithOriginalPenalties = data.map(item => ({
      ...item,
      originalPenalty: item.penaltie, // Capture the original penalty value for comparison later
    }));
  
    setResults(resultsWithOriginalPenalties);
  }, [data]);

  const handleSaveChanges = async () => {
    let resultToSave = []

    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      if (result.rank !== result.newRank || result.originalPenalty !== result.penaltie) {
        resultToSave = [...resultToSave, {
          id: result.id,
          individual_id: result.individual_id,
          event_id: result.event_id,
          rank: result.newRank,
          performance: result.performance,
          bonus_points: result.bonus_points,
          modified_by: 889,
          penaltie: result.penaltie,
        }]
      }
    }


    if (resultToSave.length === 0) {
      alert('No hay cambios para guardar');
      return;
    }

    await onSave(resultToSave);
    await fetchResults();
  }

  const fetchResults = useCallback(async () => {
    setData([]);
    setLoading(true);
    const resp = await apiRequest({url: `${API_URL}/${resultsByEventIdEndpoint}/${eventId}`});
    setData(resp?.data || []);
    setLoading(false);
  }, [apiRequest, eventId]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    console.log("datadata", data);
     console.log("preraceresultdata", preraceresults.data);
    const resultsToMap = data.map((item) => {
      return {
        ...item,
        finalracetime: parseFloat(item?.totalracetime || 0) + parseFloat(item?.penaltie || 0),
        penaltie: item?.penaltie || 0,
        // _rank: preraceresults?.data?.find((preraceresult) => preraceresult.trackIndividualId?.toString() === item.individual_id?.toString())?.m_position || 0,
        _rank: preraceresults?.data?.find((preraceresult) => preraceresult.id?.toString() === item.preraceresultid?.toString())?.m_position || 0,




      }
    })

    const sortedResults = quickSortResults(resultsToMap.sort((a, b) => a.rank - b.rank));
    const newResults = sortedResults.sort((a, b) => b.laps - a.laps).map((item, index) => {
      return {
        ...item,
        newRank: index + 1,
      }
    });

    setResults(newResults.sort((a, b) => b.laps - a.laps));
    // setResults(newResults.sort((a, b) => a.rank - b.rank));
  }, [data, preraceresults]);

  // console.log('Preraceresults', preraceresults.data)
  // console.log('Results', results)

  return (
    <>
    <div className='flex justify-between mb-4'>
      <h2 className='text-2xl font-bold'>Resultados Finales</h2>
      <div>
        <button
          className='bg-transparent hover:bg-blue-600 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-600 hover:border-transparent rounded disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
          onClick={handleSaveChanges}
          disabled={saving}
        >
          <div className='flex gap-2 items-center'>
            { saving && (<BiLoaderAlt className={classNames({ 'animate-spin': saving })} />)}
            { saving ? 'Guardando...' : 'Guardar cambios'}
          </div>
        </button>
      </div>
    </div>
    <Table>
      <THead>
        <Tr>
          <Th>Rank</Th>
          <Th>Piloto</Th>
          <Th>Equipo</Th>
          <Th>Laps</Th>
          <Th>TRace Time</Th>
          <Th>Penaltie</Th>
          <Th>Final Race Time</Th>
          <Th>Final Rank, Change</Th>
        </Tr>
      </THead>
      <TBody>
        <LoadingTableContent colSpan='8' loading={loading} />
        {results.map((result, index) => (
          <Tr className='hover:bg-gray-100' key={index}>
            <Td>{result._rank}</Td>
            <Td>{`${result.first_name} ${result.last_name}`}</Td>
            <Td>
              <TeamName teamId={result.team_id} />
            </Td>
            <Td>{result.laps}</Td>
            <Td>{result.totalracetime}</Td>
            <Td>
              <InputPenaltie value={result.penaltie} onChange={(e) => onChangePenaltie(index, e.target.value)} onKeyPress={onKeyPressPenaltie} onBlur={onBlurPenaltie} />
            </Td>
            <Td>{result.finalracetime}</Td>
            <Td>
              <div className='flex gap-2'>
                {result.newRank}
                {renderRankChange(result._rank, result.newRank)}
              </div>
            </Td>
          </Tr>
        ))}
        <NoDataRow show={!loading && data.length === 0} colSpan='8' />
      </TBody>
    </Table>
    </>
  )
}
