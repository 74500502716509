import { ProtectedSession } from "./ProtectedSession"
import Roles from "pages/Roles";
import { useUserSession } from "userContext";


export function ProtectedRoles(){
    const { userSession } = useUserSession()

    return (

        <ProtectedSession user={userSession}>
             <Roles /> 
        </ProtectedSession>
    )
}
