import React, { useState } from "react";

export function Input({ label, type, placeholder, className, onChange, validation, errorText, ...rest }) {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (onChange) {
            onChange(e);
        }
        if (validation) {
            setError(validation(newValue) ? '' : errorText);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <label className='text-gray-700 p-2'>{label}</label>
            <input type={type} placeholder={placeholder} className={className ?? 'p-2 border border-gray-300 w-72 rounded'} {...rest} value={value} onChange={handleChange} />
            {error && <p className="text-red-500 w-2/3 text-sm">{error}</p>}
        </div>
    );
}


