import React from 'react'
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import styles from './styles';
import Content from './Content';
import ResultadosFinales from './ResultadosFinales';

const headerImage = require('assets/img/incidents-pdf-header.jpg');

// Register font
Font.register({
    family: 'f1tv-namecondensed-bold',
    src: require('../../../../../assets/fonts/F1TV-2022-NameCondensed-Bold.ttf'),
});

export default function IncidentesPDF(props) {
    const { data, round, results } = props;
    const [incidents, setIncidents] = React.useState([]);

    const color1 = '#fff';
    const color2 = '#f9fafb'; // gray-50

    React.useEffect(() => {
        const dividirArray = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        }
    
        // Filter incidents based on visibility before dividing them into pages
        const filteredData = data.filter(incidente => parseInt(incidente.visibility, 10) > 0);
    
        const arrayDividido = dividirArray(filteredData, 9); // 9 incidentes por pagina after filtering
        setIncidents(arrayDividido);
    }, [data]);

    return (
        <Document>
            {incidents && incidents.map((incidente, index) => (
                <Page key={index} size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.header}>
                            <Image style={styles.img} src={headerImage} />
                            <Text style={styles.title}>RACE DIRECTOR {round?.projectname} - {round?.track_name}</Text>
                        </View>
                        {incidente.map((item, index) => (
                            <Content
                                key={index}
                                incidente={item}
                                styles={{...styles.card, backgroundColor: index % 2 === 0 ? color1 : color2}}
                                contentStyles={styles.cardContent}
                                textStyles={styles.cardText}
                            />
                        ))}
                    </View>
                </Page>
            ))}

            <ResultadosFinales results={results} />
        </Document>
    )
}
