import styles, { tableStyles } from "./styles";
import { Page, View, Image, Text } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { quickSortResults } from "utils";

const headerImage = require('assets/img/incidents-pdf-header.jpg');

const renderRankChange = (rank, newRank) => {
  const rankChange = rank - newRank;
  if (rankChange === 0) return <Text style={tableStyles.rankEqual}>--</Text>;
  if (rankChange < 0) return <Text style={tableStyles.rankDown}>{rankChange}</Text>;
  if (rankChange > 0) return <Text style={tableStyles.rankUp}>+{rankChange}</Text>;
}

function ResultsList({ data, ...rest }) {
  const [results, setResults] = useState([]);

  useEffect(() => {
    // const newResults = quickSortResults(data.sort((a, b) => a.rank - b.rank)).map((result, index) => {
    //   return {
    //     ...result,
    //     newRank: index + 1
    //   }
    // });
    // setResults(newResults);
    const resultsToMap = data.map((item) => {
      return {
        ...item,
        finalracetime: parseFloat(item?.totalracetime || 0) + parseFloat(item?.penaltie || 0),
        penaltie: item?.penaltie || 0,
      }
    });

    const sortedResults = quickSortResults(resultsToMap.sort((a, b) => a.rank - b.rank));
    const newResults = sortedResults.sort((a, b) => b.laps - a.laps).map((item, index) => {
      return {
        ...item,
        newRank: index + 1,
      }
    });

    setResults(newResults.sort((a, b) => b.laps - a.laps));
  }, [data]);

  return (
    <View style={tableStyles.table} {...rest}>
      <View style={tableStyles.tHead}>
        <View style={tableStyles.tr}>
          <Text style={tableStyles.th}>Posicion</Text>
          <Text style={tableStyles.th}>Piloto</Text>
          <Text style={tableStyles.th}>Team ID</Text>
          <Text style={tableStyles.th}>Total Race Time</Text>
          <Text style={tableStyles.th}>Penaltie</Text>
          <Text style={tableStyles.th}>New Rank</Text>
          <Text style={tableStyles.th}>Change</Text>
        </View>
      </View>
      <View style={tableStyles.tBody}>
        {results.map((result, index) => (
          <View key={index} style={tableStyles.tr}>
            <Text style={tableStyles.td}>{result.rank}</Text>
            <Text style={tableStyles.td}>{`${result.first_name} ${result.last_name}`}</Text>
            <Text style={tableStyles.td}>{result.team_id}</Text>
            <Text style={tableStyles.td}>{Math.round(parseFloat(result.totalracetime || 0) + parseFloat(result.penaltie || 0))}</Text>
            <Text style={tableStyles.td}>{result.penaltie}</Text>
            <Text style={tableStyles.td}>{result.newRank}</Text>
            <Text style={tableStyles.td}>{renderRankChange(result.rank, result.newRank)}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default function ResultadosFinales({ results }) {

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <Image style={styles.img} src={headerImage} />
          <Text style={styles.title}>Resultados Finales</Text>
        </View>
        <View>
          {results.map((item, index) => (
            <View key={index}>
              <Text style={styles.subtitle}>Event: {item.name}</Text>
              <ResultsList data={item.resultados} />
            </View>
          ))}
        </View>
      </View>
    </Page>
  )
}