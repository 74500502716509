import { Input } from "components/forms/Input";
import { Button } from "components/ui/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserSession } from "userContext";
import { login } from "utils/authUtils";

export function Login() {
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const navigate = useNavigate();

    const { setUserSession } = useUserSession();

    
    

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        login(form, setForm)
            .then((user) => {
                setUserSession(user)
            })
            .then(() => navigate(0) )
            .catch((error) => {
                if (error.code === 'auth/user-not-found') {
                    alert(`Usuario no encontrado. Por favor, cree una cuenta para ${form.email} y vuelva a intentar.`);
                } else if (error.code === 'auth/invalid-credential') {
                    alert('Contraseña o email incorrectos. Compruebe sus credenciales y vuelva a intentar');
                } else {
                    alert(`Error desconocido al iniciar sesión: ${error.message}, código de error: ${error.code}. Por favor contacte a un administrador compartiendo este error para resolverlo.`);
                }
            })


    }

    return (
        <form onSubmit={onSubmit} className="flex border border-b-gray-300 gap-4 justify-center align-middle items-center h-[60vh] flex-col">
            <Input label="Email:" type="email" name="email" onChange={handleChange}/>
            <Input label="Password:" type="password" name="password" onChange={handleChange} />
            <Button className="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-2 rounded" disabled={!form.email || !form.password}>Login</Button>
        </form>
    )
}