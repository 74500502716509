import classNames from "classnames";

export function Button ({ children, className, onClick, ...props }) {
    return (
        <button
        className={classNames(className,'disabled:bg-gray-300 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5')}
        onClick={onClick}
        {...props}
        >
            {children}
        </button>
    )
}
