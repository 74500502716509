import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import { routes } from "./config/routes.config";
import { Providers } from "providers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: routes
  }
]);

function App() {
  return(
      <Providers>
        <RouterProvider router={router} />
      </Providers>
    )
}

export default App;
