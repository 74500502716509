import Table, { LoadingTableContent, NoDataRow, TBody, THead, Td, Th, Tr } from "./Table";

export function TableLog({loading, logs}) {

  const formatDate = (firestoreTime) => {
    const date = new Date(firestoreTime.seconds * 1000);
    return date.toString(); 
  };



    return (
        <Table>
        <THead>
          <Tr>
            <Th style={{ padding: 0}}></Th>
            <Th>Email</Th>
            <Th>Fecha</Th>
            <Th>Accion</Th>
            <Th>Proyecto</Th>
            <Th>Afectado</Th>
          </Tr>
        </THead>
        <TBody>
          <LoadingTableContent colSpan='4' loading={loading} />
          { logs && logs?.map((log, index) => (
              <Tr className='hover:bg-gray-100 relative' key={index}>
                <Th style={{ padding: 0}}>
                </Th>
                <Td>{log?.authorEmail}</Td>
                <Td className=' border-none'>
                    {formatDate(log?.date)}
                </Td>
                <Td>
                  {log?.action}
                </Td>
                <Td>
                  {log?.projectId}
                </Td>
                <Td>
                  {log?.affected}
                </Td>
              </Tr>
            ))}
          
          <NoDataRow show={!loading && !logs?.length === 0} colSpan='4' />
        </TBody>
      </Table>
    )
}