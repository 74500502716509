import Table, { TBody, THead, Td, Th, Tr } from 'components/Table'
import React from 'react'

export default function VotosList({ data }) {
    return (
        <Table>
            <THead className="table-header">
                <Tr>
                    <Th>Penalidad</Th>
                    <Th>Comentario</Th>
                    <Th>Juez</Th>
                </Tr>
            </THead>
            <TBody>
                {data.map((voto, index) => (
                    <Tr key={index}>
                        <Td>{voto.penaltydescription}</Td>
                        <Td>{voto.text}</Td>
                        <Td>{voto.authid}</Td>
                    </Tr>
                ))}
                {
                    data.length === 0 && (
                        <Tr>
                            <Td colSpan={10} className='text-center'>
                                No hay votos
                            </Td>
                        </Tr>
                    )
                }
            </TBody>
        </Table>
    )
}
