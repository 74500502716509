
export function validateEmail(email) {
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(email);
}

export function validatePassword(password) {
    return /^(?=.*\d).{6,}$/.test(password);
}

export function validateRepeatPassword(password) {
    return function(repeatPassword) {
        return password === repeatPassword;
    };
}