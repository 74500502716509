import React from "react";

function Home() {
  return <>
    <div>
      <h1 className="text-4xl font-bold">
        Welcome to the Home Page!
      </h1>
    </div>
  </>;
}

export default Home;
